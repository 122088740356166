.input {
  font-family: Lato;
  padding: 12px 20px;
  margin: 8px 0;
  box-sizing: border-box;
  @extend .is-dark-blue-border;
  @extend .is-white-bg;
  border: 2px solid;
  border-radius: 4px;
  font-size: medium;
  width: 100%;
}