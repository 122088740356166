.btn {
  @extend .is-dark-blue-bg;
  @extend .is-white;
  font-family: Lato;
  max-width: 250px;
  padding: 15px;
  text-align: center;
}

.btn:hover {
  @extend .is-dark-blue;
  @extend .is-white-bg;
  outline-style: solid;
  outline-width: 3px;
  cursor: pointer;

}

.btn-variant {
  @extend .is-pink-bg;
  @extend .is-white;
  font-family: Lato;
  max-width: 250px;
  padding: 15px;
  text-align: center;
    
}

.btn-variant:hover {
  @extend .is-white;
  @extend .is-dark-blue-bg-always;
  border-style: solid;
  border-width: 3px;
  cursor: pointer;
  margin: -3px;
}

.link {
  @extend .is-dark-blue;
  
  font-weight: 100;
  text-decoration: none;
}

.link:hover {
  @extend .is-pink-always;
  cursor: pointer;
  transform: scale(1.05);
  transition: all 0.2s ease-in-out;
}

button,
input[type='submit'],
input[type='reset'] {
  @extend .is-pink;
  background: none;
  border: none;
  cursor: pointer;
  font: inherit;
  outline: inherit;
  padding: 0;
}

