@import url(https://fonts.googleapis.com/css?family=Lato:700&display=swap);
body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.margin-0 {
  margin: 0; }

.wrapper {
  box-sizing: border-box;
  margin: 0 auto;
  max-width: 1200px; }

.container-fluid {
  margin-left: auto;
  margin-right: auto;
  padding-left: 2rem;
  padding-right: 2rem; }

.row {
  display: -moz-flex;
  display: flex;
  -moz-flex: 0 1 auto;
  flex: 0 1 auto;
  -moz-flex-direction: row;
  flex-direction: row;
  flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  box-sizing: border-box; }

.row.reverse {
  -moz-flex-direction: row-reverse;
  flex-direction: row-reverse; }

.col.reverse {
  -moz-flex-direction: column-reverse;
  flex-direction: column-reverse; }

.col-xs {
  -moz-flex-grow: 0;
  flex-grow: 0;
  -ms-flex-positive: 0;
  flex-shrink: 0;
  box-sizing: border-box;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  flex-basis: auto;
  -ms-flex-preferred-size: auto; }

.col-xs-1 {
  -moz-flex-grow: 0;
  flex-grow: 0;
  -ms-flex-positive: 0;
  flex-shrink: 0;
  box-sizing: border-box;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  flex-basis: 8.33333%;
  -ms-flex-preferred-size: 8.33333%;
  max-width: 8.33333%; }

.col-xs-2 {
  -moz-flex-grow: 0;
  flex-grow: 0;
  -ms-flex-positive: 0;
  flex-shrink: 0;
  box-sizing: border-box;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  flex-basis: 16.66667%;
  -ms-flex-preferred-size: 16.66667%;
  max-width: 16.66667%; }

.col-xs-3 {
  -moz-flex-grow: 0;
  flex-grow: 0;
  -ms-flex-positive: 0;
  flex-shrink: 0;
  box-sizing: border-box;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  flex-basis: 25%;
  -ms-flex-preferred-size: 25%;
  max-width: 25%; }

.col-xs-4 {
  -moz-flex-grow: 0;
  flex-grow: 0;
  -ms-flex-positive: 0;
  flex-shrink: 0;
  box-sizing: border-box;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  flex-basis: 33.33333%;
  -ms-flex-preferred-size: 33.33333%;
  max-width: 33.33333%; }

.col-xs-5 {
  -moz-flex-grow: 0;
  flex-grow: 0;
  -ms-flex-positive: 0;
  flex-shrink: 0;
  box-sizing: border-box;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  flex-basis: 41.66667%;
  -ms-flex-preferred-size: 41.66667%;
  max-width: 41.66667%; }

.col-xs-6 {
  -moz-flex-grow: 0;
  flex-grow: 0;
  -ms-flex-positive: 0;
  flex-shrink: 0;
  box-sizing: border-box;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  flex-basis: 50%;
  -ms-flex-preferred-size: 50%;
  max-width: 50%; }

.col-xs-7 {
  -moz-flex-grow: 0;
  flex-grow: 0;
  -ms-flex-positive: 0;
  flex-shrink: 0;
  box-sizing: border-box;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  flex-basis: 58.33333%;
  -ms-flex-preferred-size: 58.33333%;
  max-width: 58.33333%; }

.col-xs-8 {
  -moz-flex-grow: 0;
  flex-grow: 0;
  -ms-flex-positive: 0;
  flex-shrink: 0;
  box-sizing: border-box;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  flex-basis: 66.66667%;
  -ms-flex-preferred-size: 66.66667%;
  max-width: 66.66667%; }

.col-xs-9 {
  -moz-flex-grow: 0;
  flex-grow: 0;
  -ms-flex-positive: 0;
  flex-shrink: 0;
  box-sizing: border-box;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  flex-basis: 75%;
  -ms-flex-preferred-size: 75%;
  max-width: 75%; }

.col-xs-10 {
  -moz-flex-grow: 0;
  flex-grow: 0;
  -ms-flex-positive: 0;
  flex-shrink: 0;
  box-sizing: border-box;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  flex-basis: 83.33333%;
  -ms-flex-preferred-size: 83.33333%;
  max-width: 83.33333%; }

.col-xs-11 {
  -moz-flex-grow: 0;
  flex-grow: 0;
  -ms-flex-positive: 0;
  flex-shrink: 0;
  box-sizing: border-box;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  flex-basis: 91.66667%;
  -ms-flex-preferred-size: 91.66667%;
  max-width: 91.66667%; }

.col-xs-12 {
  -moz-flex-grow: 0;
  flex-grow: 0;
  -ms-flex-positive: 0;
  flex-shrink: 0;
  box-sizing: border-box;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  flex-basis: 100%;
  -ms-flex-preferred-size: 100%;
  max-width: 100%; }

.col-xs-offset-0 {
  -moz-flex-grow: 0;
  flex-grow: 0;
  -ms-flex-positive: 0;
  flex-shrink: 0;
  box-sizing: border-box;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  margin-left: 0; }

.col-xs-offset-1 {
  -moz-flex-grow: 0;
  flex-grow: 0;
  -ms-flex-positive: 0;
  flex-shrink: 0;
  box-sizing: border-box;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  margin-left: 8.33333%; }

.col-xs-offset-2 {
  -moz-flex-grow: 0;
  flex-grow: 0;
  -ms-flex-positive: 0;
  flex-shrink: 0;
  box-sizing: border-box;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  margin-left: 16.66667%; }

.col-xs-offset-3 {
  -moz-flex-grow: 0;
  flex-grow: 0;
  -ms-flex-positive: 0;
  flex-shrink: 0;
  box-sizing: border-box;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  margin-left: 25%; }

.col-xs-offset-4 {
  -moz-flex-grow: 0;
  flex-grow: 0;
  -ms-flex-positive: 0;
  flex-shrink: 0;
  box-sizing: border-box;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  margin-left: 33.33333%; }

.col-xs-offset-5 {
  -moz-flex-grow: 0;
  flex-grow: 0;
  -ms-flex-positive: 0;
  flex-shrink: 0;
  box-sizing: border-box;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  margin-left: 41.66667%; }

.col-xs-offset-6 {
  -moz-flex-grow: 0;
  flex-grow: 0;
  -ms-flex-positive: 0;
  flex-shrink: 0;
  box-sizing: border-box;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  margin-left: 50%; }

.col-xs-offset-7 {
  -moz-flex-grow: 0;
  flex-grow: 0;
  -ms-flex-positive: 0;
  flex-shrink: 0;
  box-sizing: border-box;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  margin-left: 58.33333%; }

.col-xs-offset-8 {
  -moz-flex-grow: 0;
  flex-grow: 0;
  -ms-flex-positive: 0;
  flex-shrink: 0;
  box-sizing: border-box;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  margin-left: 66.66667%; }

.col-xs-offset-9 {
  -moz-flex-grow: 0;
  flex-grow: 0;
  -ms-flex-positive: 0;
  flex-shrink: 0;
  box-sizing: border-box;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  margin-left: 75%; }

.col-xs-offset-10 {
  -moz-flex-grow: 0;
  flex-grow: 0;
  -ms-flex-positive: 0;
  flex-shrink: 0;
  box-sizing: border-box;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  margin-left: 83.33333%; }

.col-xs-offset-11 {
  -moz-flex-grow: 0;
  flex-grow: 0;
  -ms-flex-positive: 0;
  flex-shrink: 0;
  box-sizing: border-box;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  margin-left: 91.66667%; }

.col-xs-offset-12 {
  -moz-flex-grow: 0;
  flex-grow: 0;
  -ms-flex-positive: 0;
  flex-shrink: 0;
  box-sizing: border-box;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  margin-left: 100%; }

.col-xs {
  -moz-flex-grow: 1;
  flex-grow: 1;
  -ms-flex-positive: 1;
  flex-basis: 0;
  -ms-flex-preferred-size: 0;
  max-width: 100%; }

.start-xs {
  -moz-justify-content: flex-start;
  justify-content: flex-start;
  text-align: left; }

.center-xs {
  -moz-justify-content: center;
  justify-content: center;
  text-align: center; }

.end-xs {
  -moz-justify-content: flex-end;
  justify-content: flex-end;
  text-align: right; }

.top-xs {
  -moz-align-items: flex-start;
  align-items: flex-start; }

.middle-xs {
  -moz-align-items: center;
  align-items: center; }

.bottom-xs {
  -moz-align-items: flex-end;
  align-items: flex-end; }

.around-xs {
  -moz-justify-content: space-around;
  justify-content: space-around; }

.between-xs {
  -moz-justify-content: space-between;
  justify-content: space-between; }

.first-xs {
  order: -1; }

.last-xs {
  order: 1; }

@media only screen and (min-width: 48em) {
  .container {
    margin: auto;
    max-width: 46rem; }
  .container-small {
    margin: auto;
    max-width: 55.2rem; }
  .col-sm {
    -moz-flex-grow: 0;
    flex-grow: 0;
    -ms-flex-positive: 0;
    flex-shrink: 0;
    box-sizing: border-box;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    flex-basis: auto;
    -ms-flex-preferred-size: auto; }
  .col-sm {
    -moz-flex-grow: 1;
    flex-grow: 1;
    -ms-flex-positive: 1;
    flex-basis: 0;
    -ms-flex-preferred-size: 0;
    max-width: 100%; }
  .start-sm {
    -moz-justify-content: flex-start;
    justify-content: flex-start;
    text-align: left; }
  .center-sm {
    -moz-justify-content: center;
    justify-content: center;
    text-align: center; }
  .end-sm {
    -moz-justify-content: flex-end;
    justify-content: flex-end;
    text-align: right; }
  .top-sm {
    -moz-align-items: flex-start;
    align-items: flex-start; }
  .middle-sm {
    -moz-align-items: center;
    align-items: center; }
  .bottom-sm {
    -moz-align-items: flex-end;
    align-items: flex-end; }
  .around-sm {
    -moz-justify-content: space-around;
    justify-content: space-around; }
  .between-sm {
    -moz-justify-content: space-between;
    justify-content: space-between; }
  .first-sm {
    order: -1; }
  .last-sm {
    order: 1; }
  .col-sm-1 {
    -moz-flex-grow: 0;
    flex-grow: 0;
    -ms-flex-positive: 0;
    flex-shrink: 0;
    box-sizing: border-box;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    flex-basis: 8.33333%;
    -ms-flex-preferred-size: 8.33333%;
    max-width: 8.33333%; }
  .col-sm-2 {
    -moz-flex-grow: 0;
    flex-grow: 0;
    -ms-flex-positive: 0;
    flex-shrink: 0;
    box-sizing: border-box;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    flex-basis: 16.66667%;
    -ms-flex-preferred-size: 16.66667%;
    max-width: 16.66667%; }
  .col-sm-3 {
    -moz-flex-grow: 0;
    flex-grow: 0;
    -ms-flex-positive: 0;
    flex-shrink: 0;
    box-sizing: border-box;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    flex-basis: 25%;
    -ms-flex-preferred-size: 25%;
    max-width: 25%; }
  .col-sm-4 {
    -moz-flex-grow: 0;
    flex-grow: 0;
    -ms-flex-positive: 0;
    flex-shrink: 0;
    box-sizing: border-box;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    flex-basis: 33.33333%;
    -ms-flex-preferred-size: 33.33333%;
    max-width: 33.33333%; }
  .col-sm-5 {
    -moz-flex-grow: 0;
    flex-grow: 0;
    -ms-flex-positive: 0;
    flex-shrink: 0;
    box-sizing: border-box;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    flex-basis: 41.66667%;
    -ms-flex-preferred-size: 41.66667%;
    max-width: 41.66667%; }
  .col-sm-6 {
    -moz-flex-grow: 0;
    flex-grow: 0;
    -ms-flex-positive: 0;
    flex-shrink: 0;
    box-sizing: border-box;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    flex-basis: 50%;
    -ms-flex-preferred-size: 50%;
    max-width: 50%; }
  .col-sm-7 {
    -moz-flex-grow: 0;
    flex-grow: 0;
    -ms-flex-positive: 0;
    flex-shrink: 0;
    box-sizing: border-box;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    flex-basis: 58.33333%;
    -ms-flex-preferred-size: 58.33333%;
    max-width: 58.33333%; }
  .col-sm-8 {
    -moz-flex-grow: 0;
    flex-grow: 0;
    -ms-flex-positive: 0;
    flex-shrink: 0;
    box-sizing: border-box;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    flex-basis: 66.66667%;
    -ms-flex-preferred-size: 66.66667%;
    max-width: 66.66667%; }
  .col-sm-9 {
    -moz-flex-grow: 0;
    flex-grow: 0;
    -ms-flex-positive: 0;
    flex-shrink: 0;
    box-sizing: border-box;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    flex-basis: 75%;
    -ms-flex-preferred-size: 75%;
    max-width: 75%; }
  .col-sm-10 {
    -moz-flex-grow: 0;
    flex-grow: 0;
    -ms-flex-positive: 0;
    flex-shrink: 0;
    box-sizing: border-box;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    flex-basis: 83.33333%;
    -ms-flex-preferred-size: 83.33333%;
    max-width: 83.33333%; }
  .col-sm-11 {
    -moz-flex-grow: 0;
    flex-grow: 0;
    -ms-flex-positive: 0;
    flex-shrink: 0;
    box-sizing: border-box;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    flex-basis: 91.66667%;
    -ms-flex-preferred-size: 91.66667%;
    max-width: 91.66667%; }
  .col-sm-12 {
    -moz-flex-grow: 0;
    flex-grow: 0;
    -ms-flex-positive: 0;
    flex-shrink: 0;
    box-sizing: border-box;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    flex-basis: 100%;
    -ms-flex-preferred-size: 100%;
    max-width: 100%; }
  .col-sm-offset-0 {
    -moz-flex-grow: 0;
    flex-grow: 0;
    -ms-flex-positive: 0;
    flex-shrink: 0;
    box-sizing: border-box;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    margin-left: 0; }
  .col-sm-offset-1 {
    -moz-flex-grow: 0;
    flex-grow: 0;
    -ms-flex-positive: 0;
    flex-shrink: 0;
    box-sizing: border-box;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    margin-left: 8.33333%; }
  .col-sm-offset-2 {
    -moz-flex-grow: 0;
    flex-grow: 0;
    -ms-flex-positive: 0;
    flex-shrink: 0;
    box-sizing: border-box;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    margin-left: 16.66667%; }
  .col-sm-offset-3 {
    -moz-flex-grow: 0;
    flex-grow: 0;
    -ms-flex-positive: 0;
    flex-shrink: 0;
    box-sizing: border-box;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    margin-left: 25%; }
  .col-sm-offset-4 {
    -moz-flex-grow: 0;
    flex-grow: 0;
    -ms-flex-positive: 0;
    flex-shrink: 0;
    box-sizing: border-box;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    margin-left: 33.33333%; }
  .col-sm-offset-5 {
    -moz-flex-grow: 0;
    flex-grow: 0;
    -ms-flex-positive: 0;
    flex-shrink: 0;
    box-sizing: border-box;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    margin-left: 41.66667%; }
  .col-sm-offset-6 {
    -moz-flex-grow: 0;
    flex-grow: 0;
    -ms-flex-positive: 0;
    flex-shrink: 0;
    box-sizing: border-box;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    margin-left: 50%; }
  .col-sm-offset-7 {
    -moz-flex-grow: 0;
    flex-grow: 0;
    -ms-flex-positive: 0;
    flex-shrink: 0;
    box-sizing: border-box;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    margin-left: 58.33333%; }
  .col-sm-offset-8 {
    -moz-flex-grow: 0;
    flex-grow: 0;
    -ms-flex-positive: 0;
    flex-shrink: 0;
    box-sizing: border-box;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    margin-left: 66.66667%; }
  .col-sm-offset-9 {
    -moz-flex-grow: 0;
    flex-grow: 0;
    -ms-flex-positive: 0;
    flex-shrink: 0;
    box-sizing: border-box;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    margin-left: 75%; }
  .col-sm-offset-10 {
    -moz-flex-grow: 0;
    flex-grow: 0;
    -ms-flex-positive: 0;
    flex-shrink: 0;
    box-sizing: border-box;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    margin-left: 83.33333%; }
  .col-sm-offset-11 {
    -moz-flex-grow: 0;
    flex-grow: 0;
    -ms-flex-positive: 0;
    flex-shrink: 0;
    box-sizing: border-box;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    margin-left: 91.66667%; }
  .col-sm-offset-12 {
    -moz-flex-grow: 0;
    flex-grow: 0;
    -ms-flex-positive: 0;
    flex-shrink: 0;
    box-sizing: border-box;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    margin-left: 100%; } }

@media only screen and (min-width: 62em) {
  .container {
    margin: auto;
    max-width: 61rem; }
  .container-small {
    margin: auto;
    max-width: 73.2rem; }
  .col-md {
    -moz-flex-grow: 0;
    flex-grow: 0;
    -ms-flex-positive: 0;
    flex-shrink: 0;
    box-sizing: border-box;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    flex-basis: auto;
    -ms-flex-preferred-size: auto; }
  .col-md {
    -moz-flex-grow: 1;
    flex-grow: 1;
    -ms-flex-positive: 1;
    flex-basis: 0;
    -ms-flex-preferred-size: 0;
    max-width: 100%; }
  .start-md {
    -moz-justify-content: flex-start;
    justify-content: flex-start;
    text-align: left; }
  .center-md {
    -moz-justify-content: center;
    justify-content: center;
    text-align: center; }
  .end-md {
    -moz-justify-content: flex-end;
    justify-content: flex-end;
    text-align: right; }
  .top-md {
    -moz-align-items: flex-start;
    align-items: flex-start; }
  .middle-md {
    -moz-align-items: center;
    align-items: center; }
  .bottom-md {
    -moz-align-items: flex-end;
    align-items: flex-end; }
  .around-md {
    -moz-justify-content: space-around;
    justify-content: space-around; }
  .between-md {
    -moz-justify-content: space-between;
    justify-content: space-between; }
  .first-md {
    order: -1; }
  .last-md {
    order: 1; }
  .col-md-1 {
    -moz-flex-grow: 0;
    flex-grow: 0;
    -ms-flex-positive: 0;
    flex-shrink: 0;
    box-sizing: border-box;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    flex-basis: 8.33333%;
    -ms-flex-preferred-size: 8.33333%;
    max-width: 8.33333%; }
  .col-md-2 {
    -moz-flex-grow: 0;
    flex-grow: 0;
    -ms-flex-positive: 0;
    flex-shrink: 0;
    box-sizing: border-box;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    flex-basis: 16.66667%;
    -ms-flex-preferred-size: 16.66667%;
    max-width: 16.66667%; }
  .col-md-3 {
    -moz-flex-grow: 0;
    flex-grow: 0;
    -ms-flex-positive: 0;
    flex-shrink: 0;
    box-sizing: border-box;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    flex-basis: 25%;
    -ms-flex-preferred-size: 25%;
    max-width: 25%; }
  .col-md-4 {
    -moz-flex-grow: 0;
    flex-grow: 0;
    -ms-flex-positive: 0;
    flex-shrink: 0;
    box-sizing: border-box;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    flex-basis: 33.33333%;
    -ms-flex-preferred-size: 33.33333%;
    max-width: 33.33333%; }
  .col-md-5 {
    -moz-flex-grow: 0;
    flex-grow: 0;
    -ms-flex-positive: 0;
    flex-shrink: 0;
    box-sizing: border-box;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    flex-basis: 41.66667%;
    -ms-flex-preferred-size: 41.66667%;
    max-width: 41.66667%; }
  .col-md-6 {
    -moz-flex-grow: 0;
    flex-grow: 0;
    -ms-flex-positive: 0;
    flex-shrink: 0;
    box-sizing: border-box;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    flex-basis: 50%;
    -ms-flex-preferred-size: 50%;
    max-width: 50%; }
  .col-md-7 {
    -moz-flex-grow: 0;
    flex-grow: 0;
    -ms-flex-positive: 0;
    flex-shrink: 0;
    box-sizing: border-box;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    flex-basis: 58.33333%;
    -ms-flex-preferred-size: 58.33333%;
    max-width: 58.33333%; }
  .col-md-8 {
    -moz-flex-grow: 0;
    flex-grow: 0;
    -ms-flex-positive: 0;
    flex-shrink: 0;
    box-sizing: border-box;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    flex-basis: 66.66667%;
    -ms-flex-preferred-size: 66.66667%;
    max-width: 66.66667%; }
  .col-md-9 {
    -moz-flex-grow: 0;
    flex-grow: 0;
    -ms-flex-positive: 0;
    flex-shrink: 0;
    box-sizing: border-box;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    flex-basis: 75%;
    -ms-flex-preferred-size: 75%;
    max-width: 75%; }
  .col-md-10 {
    -moz-flex-grow: 0;
    flex-grow: 0;
    -ms-flex-positive: 0;
    flex-shrink: 0;
    box-sizing: border-box;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    flex-basis: 83.33333%;
    -ms-flex-preferred-size: 83.33333%;
    max-width: 83.33333%; }
  .col-md-11 {
    -moz-flex-grow: 0;
    flex-grow: 0;
    -ms-flex-positive: 0;
    flex-shrink: 0;
    box-sizing: border-box;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    flex-basis: 91.66667%;
    -ms-flex-preferred-size: 91.66667%;
    max-width: 91.66667%; }
  .col-md-12 {
    -moz-flex-grow: 0;
    flex-grow: 0;
    -ms-flex-positive: 0;
    flex-shrink: 0;
    box-sizing: border-box;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    flex-basis: 100%;
    -ms-flex-preferred-size: 100%;
    max-width: 100%; }
  .col-md-offset-0 {
    -moz-flex-grow: 0;
    flex-grow: 0;
    -ms-flex-positive: 0;
    flex-shrink: 0;
    box-sizing: border-box;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    margin-left: 0; }
  .col-md-offset-1 {
    -moz-flex-grow: 0;
    flex-grow: 0;
    -ms-flex-positive: 0;
    flex-shrink: 0;
    box-sizing: border-box;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    margin-left: 8.33333%; }
  .col-md-offset-2 {
    -moz-flex-grow: 0;
    flex-grow: 0;
    -ms-flex-positive: 0;
    flex-shrink: 0;
    box-sizing: border-box;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    margin-left: 16.66667%; }
  .col-md-offset-3 {
    -moz-flex-grow: 0;
    flex-grow: 0;
    -ms-flex-positive: 0;
    flex-shrink: 0;
    box-sizing: border-box;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    margin-left: 25%; }
  .col-md-offset-4 {
    -moz-flex-grow: 0;
    flex-grow: 0;
    -ms-flex-positive: 0;
    flex-shrink: 0;
    box-sizing: border-box;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    margin-left: 33.33333%; }
  .col-md-offset-5 {
    -moz-flex-grow: 0;
    flex-grow: 0;
    -ms-flex-positive: 0;
    flex-shrink: 0;
    box-sizing: border-box;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    margin-left: 41.66667%; }
  .col-md-offset-6 {
    -moz-flex-grow: 0;
    flex-grow: 0;
    -ms-flex-positive: 0;
    flex-shrink: 0;
    box-sizing: border-box;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    margin-left: 50%; }
  .col-md-offset-7 {
    -moz-flex-grow: 0;
    flex-grow: 0;
    -ms-flex-positive: 0;
    flex-shrink: 0;
    box-sizing: border-box;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    margin-left: 58.33333%; }
  .col-md-offset-8 {
    -moz-flex-grow: 0;
    flex-grow: 0;
    -ms-flex-positive: 0;
    flex-shrink: 0;
    box-sizing: border-box;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    margin-left: 66.66667%; }
  .col-md-offset-9 {
    -moz-flex-grow: 0;
    flex-grow: 0;
    -ms-flex-positive: 0;
    flex-shrink: 0;
    box-sizing: border-box;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    margin-left: 75%; }
  .col-md-offset-10 {
    -moz-flex-grow: 0;
    flex-grow: 0;
    -ms-flex-positive: 0;
    flex-shrink: 0;
    box-sizing: border-box;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    margin-left: 83.33333%; }
  .col-md-offset-11 {
    -moz-flex-grow: 0;
    flex-grow: 0;
    -ms-flex-positive: 0;
    flex-shrink: 0;
    box-sizing: border-box;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    margin-left: 91.66667%; }
  .col-md-offset-12 {
    -moz-flex-grow: 0;
    flex-grow: 0;
    -ms-flex-positive: 0;
    flex-shrink: 0;
    box-sizing: border-box;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    margin-left: 100%; } }

@media only screen and (min-width: 75em) {
  .container {
    margin: auto;
    max-width: 71rem; }
  .container-small {
    margin: auto;
    max-width: 85.2rem; }
  .col-lg {
    -moz-flex-grow: 0;
    flex-grow: 0;
    -ms-flex-positive: 0;
    flex-shrink: 0;
    box-sizing: border-box;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    flex-basis: auto;
    -ms-flex-preferred-size: auto; }
  .col-lg {
    -moz-flex-grow: 1;
    flex-grow: 1;
    -ms-flex-positive: 1;
    flex-basis: 0;
    -ms-flex-preferred-size: 0;
    max-width: 100%; }
  .start-lg {
    -moz-justify-content: flex-start;
    justify-content: flex-start;
    text-align: left; }
  .center-lg {
    -moz-justify-content: center;
    justify-content: center;
    text-align: center; }
  .end-lg {
    -moz-justify-content: flex-end;
    justify-content: flex-end;
    text-align: right; }
  .top-lg {
    -moz-align-items: flex-start;
    align-items: flex-start; }
  .middle-lg {
    -moz-align-items: center;
    align-items: center; }
  .bottom-lg {
    -moz-align-items: flex-end;
    align-items: flex-end; }
  .around-lg {
    -moz-justify-content: space-around;
    justify-content: space-around; }
  .between-lg {
    -moz-justify-content: space-between;
    justify-content: space-between; }
  .first-lg {
    order: -1; }
  .last-lg {
    order: 1; }
  .col-lg-1 {
    -moz-flex-grow: 0;
    flex-grow: 0;
    -ms-flex-positive: 0;
    flex-shrink: 0;
    box-sizing: border-box;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    flex-basis: 8.33333%;
    -ms-flex-preferred-size: 8.33333%;
    max-width: 8.33333%; }
  .col-lg-2 {
    -moz-flex-grow: 0;
    flex-grow: 0;
    -ms-flex-positive: 0;
    flex-shrink: 0;
    box-sizing: border-box;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    flex-basis: 16.66667%;
    -ms-flex-preferred-size: 16.66667%;
    max-width: 16.66667%; }
  .col-lg-3 {
    -moz-flex-grow: 0;
    flex-grow: 0;
    -ms-flex-positive: 0;
    flex-shrink: 0;
    box-sizing: border-box;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    flex-basis: 25%;
    -ms-flex-preferred-size: 25%;
    max-width: 25%; }
  .col-lg-4 {
    -moz-flex-grow: 0;
    flex-grow: 0;
    -ms-flex-positive: 0;
    flex-shrink: 0;
    box-sizing: border-box;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    flex-basis: 33.33333%;
    -ms-flex-preferred-size: 33.33333%;
    max-width: 33.33333%; }
  .col-lg-5 {
    -moz-flex-grow: 0;
    flex-grow: 0;
    -ms-flex-positive: 0;
    flex-shrink: 0;
    box-sizing: border-box;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    flex-basis: 41.66667%;
    -ms-flex-preferred-size: 41.66667%;
    max-width: 41.66667%; }
  .col-lg-6 {
    -moz-flex-grow: 0;
    flex-grow: 0;
    -ms-flex-positive: 0;
    flex-shrink: 0;
    box-sizing: border-box;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    flex-basis: 50%;
    -ms-flex-preferred-size: 50%;
    max-width: 50%; }
  .col-lg-7 {
    -moz-flex-grow: 0;
    flex-grow: 0;
    -ms-flex-positive: 0;
    flex-shrink: 0;
    box-sizing: border-box;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    flex-basis: 58.33333%;
    -ms-flex-preferred-size: 58.33333%;
    max-width: 58.33333%; }
  .col-lg-8 {
    -moz-flex-grow: 0;
    flex-grow: 0;
    -ms-flex-positive: 0;
    flex-shrink: 0;
    box-sizing: border-box;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    flex-basis: 66.66667%;
    -ms-flex-preferred-size: 66.66667%;
    max-width: 66.66667%; }
  .col-lg-9 {
    -moz-flex-grow: 0;
    flex-grow: 0;
    -ms-flex-positive: 0;
    flex-shrink: 0;
    box-sizing: border-box;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    flex-basis: 75%;
    -ms-flex-preferred-size: 75%;
    max-width: 75%; }
  .col-lg-10 {
    -moz-flex-grow: 0;
    flex-grow: 0;
    -ms-flex-positive: 0;
    flex-shrink: 0;
    box-sizing: border-box;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    flex-basis: 83.33333%;
    -ms-flex-preferred-size: 83.33333%;
    max-width: 83.33333%; }
  .col-lg-11 {
    -moz-flex-grow: 0;
    flex-grow: 0;
    -ms-flex-positive: 0;
    flex-shrink: 0;
    box-sizing: border-box;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    flex-basis: 91.66667%;
    -ms-flex-preferred-size: 91.66667%;
    max-width: 91.66667%; }
  .col-lg-12 {
    -moz-flex-grow: 0;
    flex-grow: 0;
    -ms-flex-positive: 0;
    flex-shrink: 0;
    box-sizing: border-box;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    flex-basis: 100%;
    -ms-flex-preferred-size: 100%;
    max-width: 100%; }
  .col-lg-offset-0 {
    -moz-flex-grow: 0;
    flex-grow: 0;
    -ms-flex-positive: 0;
    flex-shrink: 0;
    box-sizing: border-box;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    margin-left: 0; }
  .col-lg-offset-1 {
    -moz-flex-grow: 0;
    flex-grow: 0;
    -ms-flex-positive: 0;
    flex-shrink: 0;
    box-sizing: border-box;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    margin-left: 8.33333%; }
  .col-lg-offset-2 {
    -moz-flex-grow: 0;
    flex-grow: 0;
    -ms-flex-positive: 0;
    flex-shrink: 0;
    box-sizing: border-box;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    margin-left: 16.66667%; }
  .col-lg-offset-3 {
    -moz-flex-grow: 0;
    flex-grow: 0;
    -ms-flex-positive: 0;
    flex-shrink: 0;
    box-sizing: border-box;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    margin-left: 25%; }
  .col-lg-offset-4 {
    -moz-flex-grow: 0;
    flex-grow: 0;
    -ms-flex-positive: 0;
    flex-shrink: 0;
    box-sizing: border-box;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    margin-left: 33.33333%; }
  .col-lg-offset-5 {
    -moz-flex-grow: 0;
    flex-grow: 0;
    -ms-flex-positive: 0;
    flex-shrink: 0;
    box-sizing: border-box;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    margin-left: 41.66667%; }
  .col-lg-offset-6 {
    -moz-flex-grow: 0;
    flex-grow: 0;
    -ms-flex-positive: 0;
    flex-shrink: 0;
    box-sizing: border-box;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    margin-left: 50%; }
  .col-lg-offset-7 {
    -moz-flex-grow: 0;
    flex-grow: 0;
    -ms-flex-positive: 0;
    flex-shrink: 0;
    box-sizing: border-box;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    margin-left: 58.33333%; }
  .col-lg-offset-8 {
    -moz-flex-grow: 0;
    flex-grow: 0;
    -ms-flex-positive: 0;
    flex-shrink: 0;
    box-sizing: border-box;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    margin-left: 66.66667%; }
  .col-lg-offset-9 {
    -moz-flex-grow: 0;
    flex-grow: 0;
    -ms-flex-positive: 0;
    flex-shrink: 0;
    box-sizing: border-box;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    margin-left: 75%; }
  .col-lg-offset-10 {
    -moz-flex-grow: 0;
    flex-grow: 0;
    -ms-flex-positive: 0;
    flex-shrink: 0;
    box-sizing: border-box;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    margin-left: 83.33333%; }
  .col-lg-offset-11 {
    -moz-flex-grow: 0;
    flex-grow: 0;
    -ms-flex-positive: 0;
    flex-shrink: 0;
    box-sizing: border-box;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    margin-left: 91.66667%; }
  .col-lg-offset-12 {
    -moz-flex-grow: 0;
    flex-grow: 0;
    -ms-flex-positive: 0;
    flex-shrink: 0;
    box-sizing: border-box;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    margin-left: 100%; } }

.is-white-bg, .input, .btn:hover {
  background-color: white; }

.is-white-border {
  border-color: white; }

.is-white, .btn, .btn-variant, .btn-variant:hover {
  color: white; }

.is-white-bg-always {
  background-color: white !important; }

.is-white-border-always {
  border-color: white !important; }

.is-white-always {
  color: white !important; }

body.dark-mode .is-white, body.dark-mode .btn, body.dark-mode .btn-variant {
  color: #1c1c1c; }

body.dark-mode .is-white-bg, body.dark-mode .input, body.dark-mode .btn:hover {
  background-color: #1c1c1c; }

body.dark-mode .is-white-border {
  border-color: #1c1c1c; }

.is-black-bg {
  background-color: #1c1c1c; }

.is-black-border {
  border-color: #1c1c1c; }

.is-black, .article pre code {
  color: #1c1c1c; }

.is-black-bg-always {
  background-color: #1c1c1c !important; }

.is-black-border-always {
  border-color: #1c1c1c !important; }

.is-black-always {
  color: #1c1c1c !important; }

body.dark-mode .is-black, body.dark-mode .article pre code, .article pre body.dark-mode code {
  color: white; }

body.dark-mode .is-black-bg {
  background-color: white; }

body.dark-mode .is-black-border {
  border-color: white; }

.is-light-brown-bg {
  background-color: #e1d2af; }

.is-light-brown-border {
  border-color: #e1d2af; }

.is-light-brown {
  color: #e1d2af; }

.is-light-brown-bg-always {
  background-color: #e1d2af !important; }

.is-light-brown-border-always {
  border-color: #e1d2af !important; }

.is-light-brown-always {
  color: #e1d2af !important; }

body.dark-mode .is-light-brown {
  color: #e1d2af; }

body.dark-mode .is-light-brown-bg {
  background-color: #e1d2af; }

body.dark-mode .is-light-brown-border {
  border-color: #e1d2af; }

.is-grey-bg {
  background-color: #2e4052; }

.is-grey-border {
  border-color: #2e4052; }

.is-grey {
  color: #2e4052; }

.is-grey-bg-always {
  background-color: #2e4052 !important; }

.is-grey-border-always {
  border-color: #2e4052 !important; }

.is-grey-always {
  color: #2e4052 !important; }

body.dark-mode .is-grey {
  color: #f9f9f9; }

body.dark-mode .is-grey-bg {
  background-color: #f9f9f9; }

body.dark-mode .is-grey-border {
  border-color: #f9f9f9; }

.is-light-grey-bg {
  background-color: #f9f9f9; }

.is-light-grey-border {
  border-color: #f9f9f9; }

.is-light-grey {
  color: #f9f9f9; }

.is-light-grey-bg-always {
  background-color: #f9f9f9 !important; }

.is-light-grey-border-always {
  border-color: #f9f9f9 !important; }

.is-light-grey-always {
  color: #f9f9f9 !important; }

body.dark-mode .is-light-grey {
  color: #121212; }

body.dark-mode .is-light-grey-bg {
  background-color: #121212; }

body.dark-mode .is-light-grey-border {
  border-color: #121212; }

.is-blue-bg {
  background-color: #35466d; }

.is-blue-border {
  border-color: #35466d; }

.is-blue {
  color: #35466d; }

.is-blue-bg-always {
  background-color: #35466d !important; }

.is-blue-border-always {
  border-color: #35466d !important; }

.is-blue-always {
  color: #35466d !important; }

body.dark-mode .is-blue {
  color: #49aab7; }

body.dark-mode .is-blue-bg {
  background-color: #49aab7; }

body.dark-mode .is-blue-border {
  border-color: #49aab7; }

.is-dark-blue-bg, .btn {
  background-color: #222d46; }

.is-dark-blue-border, .input {
  border-color: #222d46; }

.is-dark-blue, .btn:hover, .link {
  color: #222d46; }

.is-dark-blue-bg-always, .btn-variant:hover {
  background-color: #222d46 !important; }

.is-dark-blue-border-always {
  border-color: #222d46 !important; }

.is-dark-blue-always {
  color: #222d46 !important; }

body.dark-mode .is-dark-blue, body.dark-mode .btn:hover, body.dark-mode .link {
  color: white; }

body.dark-mode .is-dark-blue-bg, body.dark-mode .btn {
  background-color: white; }

body.dark-mode .is-dark-blue-border, body.dark-mode .input {
  border-color: white; }

.is-special-blue-bg {
  background-color: #067bc2; }

.is-special-blue-border {
  border-color: #067bc2; }

.is-special-blue {
  color: #067bc2; }

.is-special-blue-bg-always {
  background-color: #067bc2 !important; }

.is-special-blue-border-always {
  border-color: #067bc2 !important; }

.is-special-blue-always {
  color: #067bc2 !important; }

body.dark-mode .is-special-blue {
  color: #067bc2; }

body.dark-mode .is-special-blue-bg {
  background-color: #067bc2; }

body.dark-mode .is-special-blue-border {
  border-color: #067bc2; }

.is-light-blue-bg {
  background-color: #49aab7; }

.is-light-blue-border {
  border-color: #49aab7; }

.is-light-blue, .paper .link {
  color: #49aab7; }

.is-light-blue-bg-always {
  background-color: #49aab7 !important; }

.is-light-blue-border-always {
  border-color: #49aab7 !important; }

.is-light-blue-always, .article a {
  color: #49aab7 !important; }

body.dark-mode .is-light-blue, body.dark-mode .paper .link, .paper body.dark-mode .link {
  color: #49aab7; }

body.dark-mode .is-light-blue-bg {
  background-color: #49aab7; }

body.dark-mode .is-light-blue-border {
  border-color: #49aab7; }

.is-pink-bg, .btn-variant {
  background-color: #ea4e68; }

.is-pink-border {
  border-color: #ea4e68; }

.is-pink, button,
input[type='submit'],
input[type='reset'] {
  color: #ea4e68; }

.is-pink-bg-always, .vertical-timeline::before {
  background-color: #ea4e68 !important; }

.is-pink-border-always {
  border-color: #ea4e68 !important; }

.is-pink-always, .article ul li h2 a, .article h1, .link:hover, .vertical-timeline-element-date {
  color: #ea4e68 !important; }

body.dark-mode .is-pink, body.dark-mode button,
body.dark-mode input[type='submit'],
body.dark-mode input[type='reset'] {
  color: #000; }

body.dark-mode .is-pink-bg, body.dark-mode .btn-variant {
  background-color: #000; }

body.dark-mode .is-pink-border {
  border-color: #000; }

.is-dark-pink-bg, .article pre {
  background-color: #ef646130; }

.is-dark-pink-border {
  border-color: #ef646130; }

.is-dark-pink {
  color: #ef646130; }

.is-dark-pink-bg-always {
  background-color: #ef646130 !important; }

.is-dark-pink-border-always {
  border-color: #ef646130 !important; }

.is-dark-pink-always {
  color: #ef646130 !important; }

body.dark-mode .is-dark-pink {
  color: #2e4052; }

body.dark-mode .is-dark-pink-bg, body.dark-mode .article pre, .article body.dark-mode pre {
  background-color: #2e4052; }

body.dark-mode .is-dark-pink-border {
  border-color: #2e4052; }

.is-orange-bg {
  background-color: #ff8a1d; }

.is-orange-border {
  border-color: #ff8a1d; }

.is-orange {
  color: #ff8a1d; }

.is-orange-bg-always {
  background-color: #ff8a1d !important; }

.is-orange-border-always {
  border-color: #ff8a1d !important; }

.is-orange-always {
  color: #ff8a1d !important; }

body.dark-mode .is-orange {
  color: #ff8a1d; }

body.dark-mode .is-orange-bg {
  background-color: #ff8a1d; }

body.dark-mode .is-orange-border {
  border-color: #ff8a1d; }

.is-yellow-bg {
  background-color: #f9b806; }

.is-yellow-border {
  border-color: #f9b806; }

.is-yellow {
  color: #f9b806; }

.is-yellow-bg-always {
  background-color: #f9b806 !important; }

.is-yellow-border-always {
  border-color: #f9b806 !important; }

.is-yellow-always {
  color: #f9b806 !important; }

body.dark-mode .is-yellow {
  color: #f9b806; }

body.dark-mode .is-yellow-bg {
  background-color: #f9b806; }

body.dark-mode .is-yellow-border {
  border-color: #f9b806; }

.paper {
  background: #fff;
  box-shadow: 0 -1px 1px rgba(0, 0, 0, 0.15), 0 -10px 0 -5px #eee, 0 -10px 1px -4px rgba(0, 0, 0, 0.15), 0 -20px 0 -10px #eee, 0 -20px 1px -9px 0, 0, 0, 0.15;
  font-family: Lato;
  /* Padding for demo purposes */ }
  .paper hr {
    opacity: 0.3; }

.input {
  font-family: Lato;
  padding: 12px 20px;
  margin: 8px 0;
  box-sizing: border-box;
  border: 2px solid;
  border-radius: 4px;
  font-size: medium;
  width: 100%; }

.opacity-5 {
  opacity: 0.05 !important; }

.opacity-10 {
  opacity: 0.1 !important; }

.opacity-15 {
  opacity: 0.15 !important; }

.opacity-20 {
  opacity: 0.2 !important; }

.opacity-25 {
  opacity: 0.25 !important; }

.opacity-30 {
  opacity: 0.3 !important; }

.opacity-35 {
  opacity: 0.35 !important; }

.opacity-40 {
  opacity: 0.4 !important; }

.opacity-45 {
  opacity: 0.45 !important; }

.opacity-50 {
  opacity: 0.5 !important; }

.opacity-55 {
  opacity: 0.55 !important; }

.opacity-60 {
  opacity: 0.6 !important; }

.opacity-65 {
  opacity: 0.65 !important; }

.opacity-70 {
  opacity: 0.7 !important; }

.opacity-75 {
  opacity: 0.75 !important; }

.opacity-80 {
  opacity: 0.8 !important; }

.opacity-85 {
  opacity: 0.85 !important; }

.opacity-90 {
  opacity: 0.9 !important; }

.opacity-95 100 {
  opacity: 95 100/100 !important; }

.margin-0 {
  margin: 0px !important; }

.pad-0 {
  padding: 0px !important; }

.margin-0-t {
  margin-top: 0px !important; }

.margin-auto-t {
  margin-top: 0px !important; }

.pad-0-t {
  padding-top: 0px !important; }

.margin-0-tt {
  margin-top: 0px !important;
  margin-top: 0px !important; }

.pad-0-tt {
  padding-top: 0px !important;
  padding-top: 0px !important; }

.margin-0-tb {
  margin-top: 0px !important;
  margin-bottom: 0px !important; }

.pad-0-tb {
  padding-top: 0px !important;
  padding-bottom: 0px !important; }

.margin-0-tl {
  margin-top: 0px !important;
  margin-left: 0px !important; }

.pad-0-tl {
  padding-top: 0px !important;
  padding-left: 0px !important; }

.margin-0-tr {
  margin-top: 0px !important;
  margin-right: 0px !important; }

.pad-0-tr {
  padding-top: 0px !important;
  padding-right: 0px !important; }

.margin-0-b {
  margin-bottom: 0px !important; }

.margin-auto-b {
  margin-bottom: 0px !important; }

.pad-0-b {
  padding-bottom: 0px !important; }

.margin-0-bt {
  margin-bottom: 0px !important;
  margin-top: 0px !important; }

.pad-0-bt {
  padding-bottom: 0px !important;
  padding-top: 0px !important; }

.margin-0-bb {
  margin-bottom: 0px !important;
  margin-bottom: 0px !important; }

.pad-0-bb {
  padding-bottom: 0px !important;
  padding-bottom: 0px !important; }

.margin-0-bl {
  margin-bottom: 0px !important;
  margin-left: 0px !important; }

.pad-0-bl {
  padding-bottom: 0px !important;
  padding-left: 0px !important; }

.margin-0-br {
  margin-bottom: 0px !important;
  margin-right: 0px !important; }

.pad-0-br {
  padding-bottom: 0px !important;
  padding-right: 0px !important; }

.margin-0-l {
  margin-left: 0px !important; }

.margin-auto-l {
  margin-left: 0px !important; }

.pad-0-l {
  padding-left: 0px !important; }

.margin-0-lt {
  margin-left: 0px !important;
  margin-top: 0px !important; }

.pad-0-lt {
  padding-left: 0px !important;
  padding-top: 0px !important; }

.margin-0-lb {
  margin-left: 0px !important;
  margin-bottom: 0px !important; }

.pad-0-lb {
  padding-left: 0px !important;
  padding-bottom: 0px !important; }

.margin-0-ll {
  margin-left: 0px !important;
  margin-left: 0px !important; }

.pad-0-ll {
  padding-left: 0px !important;
  padding-left: 0px !important; }

.margin-0-lr {
  margin-left: 0px !important;
  margin-right: 0px !important; }

.pad-0-lr {
  padding-left: 0px !important;
  padding-right: 0px !important; }

.margin-0-r {
  margin-right: 0px !important; }

.margin-auto-r {
  margin-right: 0px !important; }

.pad-0-r {
  padding-right: 0px !important; }

.margin-0-rt {
  margin-right: 0px !important;
  margin-top: 0px !important; }

.pad-0-rt {
  padding-right: 0px !important;
  padding-top: 0px !important; }

.margin-0-rb {
  margin-right: 0px !important;
  margin-bottom: 0px !important; }

.pad-0-rb {
  padding-right: 0px !important;
  padding-bottom: 0px !important; }

.margin-0-rl {
  margin-right: 0px !important;
  margin-left: 0px !important; }

.pad-0-rl {
  padding-right: 0px !important;
  padding-left: 0px !important; }

.margin-0-rr {
  margin-right: 0px !important;
  margin-right: 0px !important; }

.pad-0-rr {
  padding-right: 0px !important;
  padding-right: 0px !important; }

.margin-0.2 {
  margin: 1px !important; }

.pad-0.2 {
  padding: 1px !important; }

.margin-0.2-t {
  margin-top: 1px !important; }

.margin-auto-t {
  margin-top: 1px !important; }

.pad-0.2-t {
  padding-top: 1px !important; }

.margin-0.2-tt {
  margin-top: 1px !important;
  margin-top: 1px !important; }

.pad-0.2-tt {
  padding-top: 1px !important;
  padding-top: 1px !important; }

.margin-0.2-tb {
  margin-top: 1px !important;
  margin-bottom: 1px !important; }

.pad-0.2-tb {
  padding-top: 1px !important;
  padding-bottom: 1px !important; }

.margin-0.2-tl {
  margin-top: 1px !important;
  margin-left: 1px !important; }

.pad-0.2-tl {
  padding-top: 1px !important;
  padding-left: 1px !important; }

.margin-0.2-tr {
  margin-top: 1px !important;
  margin-right: 1px !important; }

.pad-0.2-tr {
  padding-top: 1px !important;
  padding-right: 1px !important; }

.margin-0.2-b {
  margin-bottom: 1px !important; }

.margin-auto-b {
  margin-bottom: 1px !important; }

.pad-0.2-b {
  padding-bottom: 1px !important; }

.margin-0.2-bt {
  margin-bottom: 1px !important;
  margin-top: 1px !important; }

.pad-0.2-bt {
  padding-bottom: 1px !important;
  padding-top: 1px !important; }

.margin-0.2-bb {
  margin-bottom: 1px !important;
  margin-bottom: 1px !important; }

.pad-0.2-bb {
  padding-bottom: 1px !important;
  padding-bottom: 1px !important; }

.margin-0.2-bl {
  margin-bottom: 1px !important;
  margin-left: 1px !important; }

.pad-0.2-bl {
  padding-bottom: 1px !important;
  padding-left: 1px !important; }

.margin-0.2-br {
  margin-bottom: 1px !important;
  margin-right: 1px !important; }

.pad-0.2-br {
  padding-bottom: 1px !important;
  padding-right: 1px !important; }

.margin-0.2-l {
  margin-left: 1px !important; }

.margin-auto-l {
  margin-left: 1px !important; }

.pad-0.2-l {
  padding-left: 1px !important; }

.margin-0.2-lt {
  margin-left: 1px !important;
  margin-top: 1px !important; }

.pad-0.2-lt {
  padding-left: 1px !important;
  padding-top: 1px !important; }

.margin-0.2-lb {
  margin-left: 1px !important;
  margin-bottom: 1px !important; }

.pad-0.2-lb {
  padding-left: 1px !important;
  padding-bottom: 1px !important; }

.margin-0.2-ll {
  margin-left: 1px !important;
  margin-left: 1px !important; }

.pad-0.2-ll {
  padding-left: 1px !important;
  padding-left: 1px !important; }

.margin-0.2-lr {
  margin-left: 1px !important;
  margin-right: 1px !important; }

.pad-0.2-lr {
  padding-left: 1px !important;
  padding-right: 1px !important; }

.margin-0.2-r {
  margin-right: 1px !important; }

.margin-auto-r {
  margin-right: 1px !important; }

.pad-0.2-r {
  padding-right: 1px !important; }

.margin-0.2-rt {
  margin-right: 1px !important;
  margin-top: 1px !important; }

.pad-0.2-rt {
  padding-right: 1px !important;
  padding-top: 1px !important; }

.margin-0.2-rb {
  margin-right: 1px !important;
  margin-bottom: 1px !important; }

.pad-0.2-rb {
  padding-right: 1px !important;
  padding-bottom: 1px !important; }

.margin-0.2-rl {
  margin-right: 1px !important;
  margin-left: 1px !important; }

.pad-0.2-rl {
  padding-right: 1px !important;
  padding-left: 1px !important; }

.margin-0.2-rr {
  margin-right: 1px !important;
  margin-right: 1px !important; }

.pad-0.2-rr {
  padding-right: 1px !important;
  padding-right: 1px !important; }

.margin-0.4 {
  margin: 2px !important; }

.pad-0.4 {
  padding: 2px !important; }

.margin-0.4-t {
  margin-top: 2px !important; }

.margin-auto-t {
  margin-top: 2px !important; }

.pad-0.4-t {
  padding-top: 2px !important; }

.margin-0.4-tt {
  margin-top: 2px !important;
  margin-top: 2px !important; }

.pad-0.4-tt {
  padding-top: 2px !important;
  padding-top: 2px !important; }

.margin-0.4-tb {
  margin-top: 2px !important;
  margin-bottom: 2px !important; }

.pad-0.4-tb {
  padding-top: 2px !important;
  padding-bottom: 2px !important; }

.margin-0.4-tl {
  margin-top: 2px !important;
  margin-left: 2px !important; }

.pad-0.4-tl {
  padding-top: 2px !important;
  padding-left: 2px !important; }

.margin-0.4-tr {
  margin-top: 2px !important;
  margin-right: 2px !important; }

.pad-0.4-tr {
  padding-top: 2px !important;
  padding-right: 2px !important; }

.margin-0.4-b {
  margin-bottom: 2px !important; }

.margin-auto-b {
  margin-bottom: 2px !important; }

.pad-0.4-b {
  padding-bottom: 2px !important; }

.margin-0.4-bt {
  margin-bottom: 2px !important;
  margin-top: 2px !important; }

.pad-0.4-bt {
  padding-bottom: 2px !important;
  padding-top: 2px !important; }

.margin-0.4-bb {
  margin-bottom: 2px !important;
  margin-bottom: 2px !important; }

.pad-0.4-bb {
  padding-bottom: 2px !important;
  padding-bottom: 2px !important; }

.margin-0.4-bl {
  margin-bottom: 2px !important;
  margin-left: 2px !important; }

.pad-0.4-bl {
  padding-bottom: 2px !important;
  padding-left: 2px !important; }

.margin-0.4-br {
  margin-bottom: 2px !important;
  margin-right: 2px !important; }

.pad-0.4-br {
  padding-bottom: 2px !important;
  padding-right: 2px !important; }

.margin-0.4-l {
  margin-left: 2px !important; }

.margin-auto-l {
  margin-left: 2px !important; }

.pad-0.4-l {
  padding-left: 2px !important; }

.margin-0.4-lt {
  margin-left: 2px !important;
  margin-top: 2px !important; }

.pad-0.4-lt {
  padding-left: 2px !important;
  padding-top: 2px !important; }

.margin-0.4-lb {
  margin-left: 2px !important;
  margin-bottom: 2px !important; }

.pad-0.4-lb {
  padding-left: 2px !important;
  padding-bottom: 2px !important; }

.margin-0.4-ll {
  margin-left: 2px !important;
  margin-left: 2px !important; }

.pad-0.4-ll {
  padding-left: 2px !important;
  padding-left: 2px !important; }

.margin-0.4-lr {
  margin-left: 2px !important;
  margin-right: 2px !important; }

.pad-0.4-lr {
  padding-left: 2px !important;
  padding-right: 2px !important; }

.margin-0.4-r {
  margin-right: 2px !important; }

.margin-auto-r {
  margin-right: 2px !important; }

.pad-0.4-r {
  padding-right: 2px !important; }

.margin-0.4-rt {
  margin-right: 2px !important;
  margin-top: 2px !important; }

.pad-0.4-rt {
  padding-right: 2px !important;
  padding-top: 2px !important; }

.margin-0.4-rb {
  margin-right: 2px !important;
  margin-bottom: 2px !important; }

.pad-0.4-rb {
  padding-right: 2px !important;
  padding-bottom: 2px !important; }

.margin-0.4-rl {
  margin-right: 2px !important;
  margin-left: 2px !important; }

.pad-0.4-rl {
  padding-right: 2px !important;
  padding-left: 2px !important; }

.margin-0.4-rr {
  margin-right: 2px !important;
  margin-right: 2px !important; }

.pad-0.4-rr {
  padding-right: 2px !important;
  padding-right: 2px !important; }

.margin-0.6 {
  margin: 3px !important; }

.pad-0.6 {
  padding: 3px !important; }

.margin-0.6-t {
  margin-top: 3px !important; }

.margin-auto-t {
  margin-top: 3px !important; }

.pad-0.6-t {
  padding-top: 3px !important; }

.margin-0.6-tt {
  margin-top: 3px !important;
  margin-top: 3px !important; }

.pad-0.6-tt {
  padding-top: 3px !important;
  padding-top: 3px !important; }

.margin-0.6-tb {
  margin-top: 3px !important;
  margin-bottom: 3px !important; }

.pad-0.6-tb {
  padding-top: 3px !important;
  padding-bottom: 3px !important; }

.margin-0.6-tl {
  margin-top: 3px !important;
  margin-left: 3px !important; }

.pad-0.6-tl {
  padding-top: 3px !important;
  padding-left: 3px !important; }

.margin-0.6-tr {
  margin-top: 3px !important;
  margin-right: 3px !important; }

.pad-0.6-tr {
  padding-top: 3px !important;
  padding-right: 3px !important; }

.margin-0.6-b {
  margin-bottom: 3px !important; }

.margin-auto-b {
  margin-bottom: 3px !important; }

.pad-0.6-b {
  padding-bottom: 3px !important; }

.margin-0.6-bt {
  margin-bottom: 3px !important;
  margin-top: 3px !important; }

.pad-0.6-bt {
  padding-bottom: 3px !important;
  padding-top: 3px !important; }

.margin-0.6-bb {
  margin-bottom: 3px !important;
  margin-bottom: 3px !important; }

.pad-0.6-bb {
  padding-bottom: 3px !important;
  padding-bottom: 3px !important; }

.margin-0.6-bl {
  margin-bottom: 3px !important;
  margin-left: 3px !important; }

.pad-0.6-bl {
  padding-bottom: 3px !important;
  padding-left: 3px !important; }

.margin-0.6-br {
  margin-bottom: 3px !important;
  margin-right: 3px !important; }

.pad-0.6-br {
  padding-bottom: 3px !important;
  padding-right: 3px !important; }

.margin-0.6-l {
  margin-left: 3px !important; }

.margin-auto-l {
  margin-left: 3px !important; }

.pad-0.6-l {
  padding-left: 3px !important; }

.margin-0.6-lt {
  margin-left: 3px !important;
  margin-top: 3px !important; }

.pad-0.6-lt {
  padding-left: 3px !important;
  padding-top: 3px !important; }

.margin-0.6-lb {
  margin-left: 3px !important;
  margin-bottom: 3px !important; }

.pad-0.6-lb {
  padding-left: 3px !important;
  padding-bottom: 3px !important; }

.margin-0.6-ll {
  margin-left: 3px !important;
  margin-left: 3px !important; }

.pad-0.6-ll {
  padding-left: 3px !important;
  padding-left: 3px !important; }

.margin-0.6-lr {
  margin-left: 3px !important;
  margin-right: 3px !important; }

.pad-0.6-lr {
  padding-left: 3px !important;
  padding-right: 3px !important; }

.margin-0.6-r {
  margin-right: 3px !important; }

.margin-auto-r {
  margin-right: 3px !important; }

.pad-0.6-r {
  padding-right: 3px !important; }

.margin-0.6-rt {
  margin-right: 3px !important;
  margin-top: 3px !important; }

.pad-0.6-rt {
  padding-right: 3px !important;
  padding-top: 3px !important; }

.margin-0.6-rb {
  margin-right: 3px !important;
  margin-bottom: 3px !important; }

.pad-0.6-rb {
  padding-right: 3px !important;
  padding-bottom: 3px !important; }

.margin-0.6-rl {
  margin-right: 3px !important;
  margin-left: 3px !important; }

.pad-0.6-rl {
  padding-right: 3px !important;
  padding-left: 3px !important; }

.margin-0.6-rr {
  margin-right: 3px !important;
  margin-right: 3px !important; }

.pad-0.6-rr {
  padding-right: 3px !important;
  padding-right: 3px !important; }

.margin-0.8 {
  margin: 4px !important; }

.pad-0.8 {
  padding: 4px !important; }

.margin-0.8-t {
  margin-top: 4px !important; }

.margin-auto-t {
  margin-top: 4px !important; }

.pad-0.8-t {
  padding-top: 4px !important; }

.margin-0.8-tt {
  margin-top: 4px !important;
  margin-top: 4px !important; }

.pad-0.8-tt {
  padding-top: 4px !important;
  padding-top: 4px !important; }

.margin-0.8-tb {
  margin-top: 4px !important;
  margin-bottom: 4px !important; }

.pad-0.8-tb {
  padding-top: 4px !important;
  padding-bottom: 4px !important; }

.margin-0.8-tl {
  margin-top: 4px !important;
  margin-left: 4px !important; }

.pad-0.8-tl {
  padding-top: 4px !important;
  padding-left: 4px !important; }

.margin-0.8-tr {
  margin-top: 4px !important;
  margin-right: 4px !important; }

.pad-0.8-tr {
  padding-top: 4px !important;
  padding-right: 4px !important; }

.margin-0.8-b {
  margin-bottom: 4px !important; }

.margin-auto-b {
  margin-bottom: 4px !important; }

.pad-0.8-b {
  padding-bottom: 4px !important; }

.margin-0.8-bt {
  margin-bottom: 4px !important;
  margin-top: 4px !important; }

.pad-0.8-bt {
  padding-bottom: 4px !important;
  padding-top: 4px !important; }

.margin-0.8-bb {
  margin-bottom: 4px !important;
  margin-bottom: 4px !important; }

.pad-0.8-bb {
  padding-bottom: 4px !important;
  padding-bottom: 4px !important; }

.margin-0.8-bl {
  margin-bottom: 4px !important;
  margin-left: 4px !important; }

.pad-0.8-bl {
  padding-bottom: 4px !important;
  padding-left: 4px !important; }

.margin-0.8-br {
  margin-bottom: 4px !important;
  margin-right: 4px !important; }

.pad-0.8-br {
  padding-bottom: 4px !important;
  padding-right: 4px !important; }

.margin-0.8-l {
  margin-left: 4px !important; }

.margin-auto-l {
  margin-left: 4px !important; }

.pad-0.8-l {
  padding-left: 4px !important; }

.margin-0.8-lt {
  margin-left: 4px !important;
  margin-top: 4px !important; }

.pad-0.8-lt {
  padding-left: 4px !important;
  padding-top: 4px !important; }

.margin-0.8-lb {
  margin-left: 4px !important;
  margin-bottom: 4px !important; }

.pad-0.8-lb {
  padding-left: 4px !important;
  padding-bottom: 4px !important; }

.margin-0.8-ll {
  margin-left: 4px !important;
  margin-left: 4px !important; }

.pad-0.8-ll {
  padding-left: 4px !important;
  padding-left: 4px !important; }

.margin-0.8-lr {
  margin-left: 4px !important;
  margin-right: 4px !important; }

.pad-0.8-lr {
  padding-left: 4px !important;
  padding-right: 4px !important; }

.margin-0.8-r {
  margin-right: 4px !important; }

.margin-auto-r {
  margin-right: 4px !important; }

.pad-0.8-r {
  padding-right: 4px !important; }

.margin-0.8-rt {
  margin-right: 4px !important;
  margin-top: 4px !important; }

.pad-0.8-rt {
  padding-right: 4px !important;
  padding-top: 4px !important; }

.margin-0.8-rb {
  margin-right: 4px !important;
  margin-bottom: 4px !important; }

.pad-0.8-rb {
  padding-right: 4px !important;
  padding-bottom: 4px !important; }

.margin-0.8-rl {
  margin-right: 4px !important;
  margin-left: 4px !important; }

.pad-0.8-rl {
  padding-right: 4px !important;
  padding-left: 4px !important; }

.margin-0.8-rr {
  margin-right: 4px !important;
  margin-right: 4px !important; }

.pad-0.8-rr {
  padding-right: 4px !important;
  padding-right: 4px !important; }

.margin-1 {
  margin: 5px !important; }

.pad-1 {
  padding: 5px !important; }

.margin-1-t {
  margin-top: 5px !important; }

.margin-auto-t {
  margin-top: 5px !important; }

.pad-1-t {
  padding-top: 5px !important; }

.margin-1-tt {
  margin-top: 5px !important;
  margin-top: 5px !important; }

.pad-1-tt {
  padding-top: 5px !important;
  padding-top: 5px !important; }

.margin-1-tb {
  margin-top: 5px !important;
  margin-bottom: 5px !important; }

.pad-1-tb {
  padding-top: 5px !important;
  padding-bottom: 5px !important; }

.margin-1-tl {
  margin-top: 5px !important;
  margin-left: 5px !important; }

.pad-1-tl {
  padding-top: 5px !important;
  padding-left: 5px !important; }

.margin-1-tr {
  margin-top: 5px !important;
  margin-right: 5px !important; }

.pad-1-tr {
  padding-top: 5px !important;
  padding-right: 5px !important; }

.margin-1-b {
  margin-bottom: 5px !important; }

.margin-auto-b {
  margin-bottom: 5px !important; }

.pad-1-b {
  padding-bottom: 5px !important; }

.margin-1-bt {
  margin-bottom: 5px !important;
  margin-top: 5px !important; }

.pad-1-bt {
  padding-bottom: 5px !important;
  padding-top: 5px !important; }

.margin-1-bb {
  margin-bottom: 5px !important;
  margin-bottom: 5px !important; }

.pad-1-bb {
  padding-bottom: 5px !important;
  padding-bottom: 5px !important; }

.margin-1-bl {
  margin-bottom: 5px !important;
  margin-left: 5px !important; }

.pad-1-bl {
  padding-bottom: 5px !important;
  padding-left: 5px !important; }

.margin-1-br {
  margin-bottom: 5px !important;
  margin-right: 5px !important; }

.pad-1-br {
  padding-bottom: 5px !important;
  padding-right: 5px !important; }

.margin-1-l {
  margin-left: 5px !important; }

.margin-auto-l {
  margin-left: 5px !important; }

.pad-1-l {
  padding-left: 5px !important; }

.margin-1-lt {
  margin-left: 5px !important;
  margin-top: 5px !important; }

.pad-1-lt {
  padding-left: 5px !important;
  padding-top: 5px !important; }

.margin-1-lb {
  margin-left: 5px !important;
  margin-bottom: 5px !important; }

.pad-1-lb {
  padding-left: 5px !important;
  padding-bottom: 5px !important; }

.margin-1-ll {
  margin-left: 5px !important;
  margin-left: 5px !important; }

.pad-1-ll {
  padding-left: 5px !important;
  padding-left: 5px !important; }

.margin-1-lr {
  margin-left: 5px !important;
  margin-right: 5px !important; }

.pad-1-lr {
  padding-left: 5px !important;
  padding-right: 5px !important; }

.margin-1-r {
  margin-right: 5px !important; }

.margin-auto-r {
  margin-right: 5px !important; }

.pad-1-r {
  padding-right: 5px !important; }

.margin-1-rt {
  margin-right: 5px !important;
  margin-top: 5px !important; }

.pad-1-rt {
  padding-right: 5px !important;
  padding-top: 5px !important; }

.margin-1-rb {
  margin-right: 5px !important;
  margin-bottom: 5px !important; }

.pad-1-rb {
  padding-right: 5px !important;
  padding-bottom: 5px !important; }

.margin-1-rl {
  margin-right: 5px !important;
  margin-left: 5px !important; }

.pad-1-rl {
  padding-right: 5px !important;
  padding-left: 5px !important; }

.margin-1-rr {
  margin-right: 5px !important;
  margin-right: 5px !important; }

.pad-1-rr {
  padding-right: 5px !important;
  padding-right: 5px !important; }

.margin-2 {
  margin: 10px !important; }

.pad-2 {
  padding: 10px !important; }

.margin-2-t {
  margin-top: 10px !important; }

.margin-auto-t {
  margin-top: 10px !important; }

.pad-2-t {
  padding-top: 10px !important; }

.margin-2-tt {
  margin-top: 10px !important;
  margin-top: 10px !important; }

.pad-2-tt {
  padding-top: 10px !important;
  padding-top: 10px !important; }

.margin-2-tb {
  margin-top: 10px !important;
  margin-bottom: 10px !important; }

.pad-2-tb {
  padding-top: 10px !important;
  padding-bottom: 10px !important; }

.margin-2-tl {
  margin-top: 10px !important;
  margin-left: 10px !important; }

.pad-2-tl {
  padding-top: 10px !important;
  padding-left: 10px !important; }

.margin-2-tr {
  margin-top: 10px !important;
  margin-right: 10px !important; }

.pad-2-tr {
  padding-top: 10px !important;
  padding-right: 10px !important; }

.margin-2-b {
  margin-bottom: 10px !important; }

.margin-auto-b {
  margin-bottom: 10px !important; }

.pad-2-b {
  padding-bottom: 10px !important; }

.margin-2-bt {
  margin-bottom: 10px !important;
  margin-top: 10px !important; }

.pad-2-bt {
  padding-bottom: 10px !important;
  padding-top: 10px !important; }

.margin-2-bb {
  margin-bottom: 10px !important;
  margin-bottom: 10px !important; }

.pad-2-bb {
  padding-bottom: 10px !important;
  padding-bottom: 10px !important; }

.margin-2-bl {
  margin-bottom: 10px !important;
  margin-left: 10px !important; }

.pad-2-bl {
  padding-bottom: 10px !important;
  padding-left: 10px !important; }

.margin-2-br {
  margin-bottom: 10px !important;
  margin-right: 10px !important; }

.pad-2-br {
  padding-bottom: 10px !important;
  padding-right: 10px !important; }

.margin-2-l {
  margin-left: 10px !important; }

.margin-auto-l {
  margin-left: 10px !important; }

.pad-2-l {
  padding-left: 10px !important; }

.margin-2-lt {
  margin-left: 10px !important;
  margin-top: 10px !important; }

.pad-2-lt {
  padding-left: 10px !important;
  padding-top: 10px !important; }

.margin-2-lb {
  margin-left: 10px !important;
  margin-bottom: 10px !important; }

.pad-2-lb {
  padding-left: 10px !important;
  padding-bottom: 10px !important; }

.margin-2-ll {
  margin-left: 10px !important;
  margin-left: 10px !important; }

.pad-2-ll {
  padding-left: 10px !important;
  padding-left: 10px !important; }

.margin-2-lr {
  margin-left: 10px !important;
  margin-right: 10px !important; }

.pad-2-lr {
  padding-left: 10px !important;
  padding-right: 10px !important; }

.margin-2-r {
  margin-right: 10px !important; }

.margin-auto-r {
  margin-right: 10px !important; }

.pad-2-r {
  padding-right: 10px !important; }

.margin-2-rt {
  margin-right: 10px !important;
  margin-top: 10px !important; }

.pad-2-rt {
  padding-right: 10px !important;
  padding-top: 10px !important; }

.margin-2-rb {
  margin-right: 10px !important;
  margin-bottom: 10px !important; }

.pad-2-rb {
  padding-right: 10px !important;
  padding-bottom: 10px !important; }

.margin-2-rl {
  margin-right: 10px !important;
  margin-left: 10px !important; }

.pad-2-rl {
  padding-right: 10px !important;
  padding-left: 10px !important; }

.margin-2-rr {
  margin-right: 10px !important;
  margin-right: 10px !important; }

.pad-2-rr {
  padding-right: 10px !important;
  padding-right: 10px !important; }

.margin-3 {
  margin: 15px !important; }

.pad-3 {
  padding: 15px !important; }

.margin-3-t {
  margin-top: 15px !important; }

.margin-auto-t {
  margin-top: 15px !important; }

.pad-3-t {
  padding-top: 15px !important; }

.margin-3-tt {
  margin-top: 15px !important;
  margin-top: 15px !important; }

.pad-3-tt {
  padding-top: 15px !important;
  padding-top: 15px !important; }

.margin-3-tb {
  margin-top: 15px !important;
  margin-bottom: 15px !important; }

.pad-3-tb {
  padding-top: 15px !important;
  padding-bottom: 15px !important; }

.margin-3-tl {
  margin-top: 15px !important;
  margin-left: 15px !important; }

.pad-3-tl {
  padding-top: 15px !important;
  padding-left: 15px !important; }

.margin-3-tr {
  margin-top: 15px !important;
  margin-right: 15px !important; }

.pad-3-tr {
  padding-top: 15px !important;
  padding-right: 15px !important; }

.margin-3-b {
  margin-bottom: 15px !important; }

.margin-auto-b {
  margin-bottom: 15px !important; }

.pad-3-b {
  padding-bottom: 15px !important; }

.margin-3-bt {
  margin-bottom: 15px !important;
  margin-top: 15px !important; }

.pad-3-bt {
  padding-bottom: 15px !important;
  padding-top: 15px !important; }

.margin-3-bb {
  margin-bottom: 15px !important;
  margin-bottom: 15px !important; }

.pad-3-bb {
  padding-bottom: 15px !important;
  padding-bottom: 15px !important; }

.margin-3-bl {
  margin-bottom: 15px !important;
  margin-left: 15px !important; }

.pad-3-bl {
  padding-bottom: 15px !important;
  padding-left: 15px !important; }

.margin-3-br {
  margin-bottom: 15px !important;
  margin-right: 15px !important; }

.pad-3-br {
  padding-bottom: 15px !important;
  padding-right: 15px !important; }

.margin-3-l {
  margin-left: 15px !important; }

.margin-auto-l {
  margin-left: 15px !important; }

.pad-3-l {
  padding-left: 15px !important; }

.margin-3-lt {
  margin-left: 15px !important;
  margin-top: 15px !important; }

.pad-3-lt {
  padding-left: 15px !important;
  padding-top: 15px !important; }

.margin-3-lb {
  margin-left: 15px !important;
  margin-bottom: 15px !important; }

.pad-3-lb {
  padding-left: 15px !important;
  padding-bottom: 15px !important; }

.margin-3-ll {
  margin-left: 15px !important;
  margin-left: 15px !important; }

.pad-3-ll {
  padding-left: 15px !important;
  padding-left: 15px !important; }

.margin-3-lr {
  margin-left: 15px !important;
  margin-right: 15px !important; }

.pad-3-lr {
  padding-left: 15px !important;
  padding-right: 15px !important; }

.margin-3-r {
  margin-right: 15px !important; }

.margin-auto-r {
  margin-right: 15px !important; }

.pad-3-r {
  padding-right: 15px !important; }

.margin-3-rt {
  margin-right: 15px !important;
  margin-top: 15px !important; }

.pad-3-rt {
  padding-right: 15px !important;
  padding-top: 15px !important; }

.margin-3-rb {
  margin-right: 15px !important;
  margin-bottom: 15px !important; }

.pad-3-rb {
  padding-right: 15px !important;
  padding-bottom: 15px !important; }

.margin-3-rl {
  margin-right: 15px !important;
  margin-left: 15px !important; }

.pad-3-rl {
  padding-right: 15px !important;
  padding-left: 15px !important; }

.margin-3-rr {
  margin-right: 15px !important;
  margin-right: 15px !important; }

.pad-3-rr {
  padding-right: 15px !important;
  padding-right: 15px !important; }

.margin-4 {
  margin: 20px !important; }

.pad-4 {
  padding: 20px !important; }

.margin-4-t {
  margin-top: 20px !important; }

.margin-auto-t {
  margin-top: 20px !important; }

.pad-4-t {
  padding-top: 20px !important; }

.margin-4-tt {
  margin-top: 20px !important;
  margin-top: 20px !important; }

.pad-4-tt {
  padding-top: 20px !important;
  padding-top: 20px !important; }

.margin-4-tb {
  margin-top: 20px !important;
  margin-bottom: 20px !important; }

.pad-4-tb {
  padding-top: 20px !important;
  padding-bottom: 20px !important; }

.margin-4-tl {
  margin-top: 20px !important;
  margin-left: 20px !important; }

.pad-4-tl {
  padding-top: 20px !important;
  padding-left: 20px !important; }

.margin-4-tr {
  margin-top: 20px !important;
  margin-right: 20px !important; }

.pad-4-tr {
  padding-top: 20px !important;
  padding-right: 20px !important; }

.margin-4-b {
  margin-bottom: 20px !important; }

.margin-auto-b {
  margin-bottom: 20px !important; }

.pad-4-b {
  padding-bottom: 20px !important; }

.margin-4-bt {
  margin-bottom: 20px !important;
  margin-top: 20px !important; }

.pad-4-bt {
  padding-bottom: 20px !important;
  padding-top: 20px !important; }

.margin-4-bb {
  margin-bottom: 20px !important;
  margin-bottom: 20px !important; }

.pad-4-bb {
  padding-bottom: 20px !important;
  padding-bottom: 20px !important; }

.margin-4-bl {
  margin-bottom: 20px !important;
  margin-left: 20px !important; }

.pad-4-bl {
  padding-bottom: 20px !important;
  padding-left: 20px !important; }

.margin-4-br {
  margin-bottom: 20px !important;
  margin-right: 20px !important; }

.pad-4-br {
  padding-bottom: 20px !important;
  padding-right: 20px !important; }

.margin-4-l {
  margin-left: 20px !important; }

.margin-auto-l {
  margin-left: 20px !important; }

.pad-4-l {
  padding-left: 20px !important; }

.margin-4-lt {
  margin-left: 20px !important;
  margin-top: 20px !important; }

.pad-4-lt {
  padding-left: 20px !important;
  padding-top: 20px !important; }

.margin-4-lb {
  margin-left: 20px !important;
  margin-bottom: 20px !important; }

.pad-4-lb {
  padding-left: 20px !important;
  padding-bottom: 20px !important; }

.margin-4-ll {
  margin-left: 20px !important;
  margin-left: 20px !important; }

.pad-4-ll {
  padding-left: 20px !important;
  padding-left: 20px !important; }

.margin-4-lr {
  margin-left: 20px !important;
  margin-right: 20px !important; }

.pad-4-lr {
  padding-left: 20px !important;
  padding-right: 20px !important; }

.margin-4-r {
  margin-right: 20px !important; }

.margin-auto-r {
  margin-right: 20px !important; }

.pad-4-r {
  padding-right: 20px !important; }

.margin-4-rt {
  margin-right: 20px !important;
  margin-top: 20px !important; }

.pad-4-rt {
  padding-right: 20px !important;
  padding-top: 20px !important; }

.margin-4-rb {
  margin-right: 20px !important;
  margin-bottom: 20px !important; }

.pad-4-rb {
  padding-right: 20px !important;
  padding-bottom: 20px !important; }

.margin-4-rl {
  margin-right: 20px !important;
  margin-left: 20px !important; }

.pad-4-rl {
  padding-right: 20px !important;
  padding-left: 20px !important; }

.margin-4-rr {
  margin-right: 20px !important;
  margin-right: 20px !important; }

.pad-4-rr {
  padding-right: 20px !important;
  padding-right: 20px !important; }

.margin-5 {
  margin: 25px !important; }

.pad-5 {
  padding: 25px !important; }

.margin-5-t {
  margin-top: 25px !important; }

.margin-auto-t {
  margin-top: 25px !important; }

.pad-5-t {
  padding-top: 25px !important; }

.margin-5-tt {
  margin-top: 25px !important;
  margin-top: 25px !important; }

.pad-5-tt {
  padding-top: 25px !important;
  padding-top: 25px !important; }

.margin-5-tb {
  margin-top: 25px !important;
  margin-bottom: 25px !important; }

.pad-5-tb {
  padding-top: 25px !important;
  padding-bottom: 25px !important; }

.margin-5-tl {
  margin-top: 25px !important;
  margin-left: 25px !important; }

.pad-5-tl {
  padding-top: 25px !important;
  padding-left: 25px !important; }

.margin-5-tr {
  margin-top: 25px !important;
  margin-right: 25px !important; }

.pad-5-tr {
  padding-top: 25px !important;
  padding-right: 25px !important; }

.margin-5-b {
  margin-bottom: 25px !important; }

.margin-auto-b {
  margin-bottom: 25px !important; }

.pad-5-b {
  padding-bottom: 25px !important; }

.margin-5-bt {
  margin-bottom: 25px !important;
  margin-top: 25px !important; }

.pad-5-bt {
  padding-bottom: 25px !important;
  padding-top: 25px !important; }

.margin-5-bb {
  margin-bottom: 25px !important;
  margin-bottom: 25px !important; }

.pad-5-bb {
  padding-bottom: 25px !important;
  padding-bottom: 25px !important; }

.margin-5-bl {
  margin-bottom: 25px !important;
  margin-left: 25px !important; }

.pad-5-bl {
  padding-bottom: 25px !important;
  padding-left: 25px !important; }

.margin-5-br {
  margin-bottom: 25px !important;
  margin-right: 25px !important; }

.pad-5-br {
  padding-bottom: 25px !important;
  padding-right: 25px !important; }

.margin-5-l {
  margin-left: 25px !important; }

.margin-auto-l {
  margin-left: 25px !important; }

.pad-5-l {
  padding-left: 25px !important; }

.margin-5-lt {
  margin-left: 25px !important;
  margin-top: 25px !important; }

.pad-5-lt {
  padding-left: 25px !important;
  padding-top: 25px !important; }

.margin-5-lb {
  margin-left: 25px !important;
  margin-bottom: 25px !important; }

.pad-5-lb {
  padding-left: 25px !important;
  padding-bottom: 25px !important; }

.margin-5-ll {
  margin-left: 25px !important;
  margin-left: 25px !important; }

.pad-5-ll {
  padding-left: 25px !important;
  padding-left: 25px !important; }

.margin-5-lr {
  margin-left: 25px !important;
  margin-right: 25px !important; }

.pad-5-lr {
  padding-left: 25px !important;
  padding-right: 25px !important; }

.margin-5-r {
  margin-right: 25px !important; }

.margin-auto-r {
  margin-right: 25px !important; }

.pad-5-r {
  padding-right: 25px !important; }

.margin-5-rt {
  margin-right: 25px !important;
  margin-top: 25px !important; }

.pad-5-rt {
  padding-right: 25px !important;
  padding-top: 25px !important; }

.margin-5-rb {
  margin-right: 25px !important;
  margin-bottom: 25px !important; }

.pad-5-rb {
  padding-right: 25px !important;
  padding-bottom: 25px !important; }

.margin-5-rl {
  margin-right: 25px !important;
  margin-left: 25px !important; }

.pad-5-rl {
  padding-right: 25px !important;
  padding-left: 25px !important; }

.margin-5-rr {
  margin-right: 25px !important;
  margin-right: 25px !important; }

.pad-5-rr {
  padding-right: 25px !important;
  padding-right: 25px !important; }

.margin-6 {
  margin: 30px !important; }

.pad-6 {
  padding: 30px !important; }

.margin-6-t {
  margin-top: 30px !important; }

.margin-auto-t {
  margin-top: 30px !important; }

.pad-6-t {
  padding-top: 30px !important; }

.margin-6-tt {
  margin-top: 30px !important;
  margin-top: 30px !important; }

.pad-6-tt {
  padding-top: 30px !important;
  padding-top: 30px !important; }

.margin-6-tb {
  margin-top: 30px !important;
  margin-bottom: 30px !important; }

.pad-6-tb {
  padding-top: 30px !important;
  padding-bottom: 30px !important; }

.margin-6-tl {
  margin-top: 30px !important;
  margin-left: 30px !important; }

.pad-6-tl {
  padding-top: 30px !important;
  padding-left: 30px !important; }

.margin-6-tr {
  margin-top: 30px !important;
  margin-right: 30px !important; }

.pad-6-tr {
  padding-top: 30px !important;
  padding-right: 30px !important; }

.margin-6-b {
  margin-bottom: 30px !important; }

.margin-auto-b {
  margin-bottom: 30px !important; }

.pad-6-b {
  padding-bottom: 30px !important; }

.margin-6-bt {
  margin-bottom: 30px !important;
  margin-top: 30px !important; }

.pad-6-bt {
  padding-bottom: 30px !important;
  padding-top: 30px !important; }

.margin-6-bb {
  margin-bottom: 30px !important;
  margin-bottom: 30px !important; }

.pad-6-bb {
  padding-bottom: 30px !important;
  padding-bottom: 30px !important; }

.margin-6-bl {
  margin-bottom: 30px !important;
  margin-left: 30px !important; }

.pad-6-bl {
  padding-bottom: 30px !important;
  padding-left: 30px !important; }

.margin-6-br {
  margin-bottom: 30px !important;
  margin-right: 30px !important; }

.pad-6-br {
  padding-bottom: 30px !important;
  padding-right: 30px !important; }

.margin-6-l {
  margin-left: 30px !important; }

.margin-auto-l {
  margin-left: 30px !important; }

.pad-6-l {
  padding-left: 30px !important; }

.margin-6-lt {
  margin-left: 30px !important;
  margin-top: 30px !important; }

.pad-6-lt {
  padding-left: 30px !important;
  padding-top: 30px !important; }

.margin-6-lb {
  margin-left: 30px !important;
  margin-bottom: 30px !important; }

.pad-6-lb {
  padding-left: 30px !important;
  padding-bottom: 30px !important; }

.margin-6-ll {
  margin-left: 30px !important;
  margin-left: 30px !important; }

.pad-6-ll {
  padding-left: 30px !important;
  padding-left: 30px !important; }

.margin-6-lr {
  margin-left: 30px !important;
  margin-right: 30px !important; }

.pad-6-lr {
  padding-left: 30px !important;
  padding-right: 30px !important; }

.margin-6-r {
  margin-right: 30px !important; }

.margin-auto-r {
  margin-right: 30px !important; }

.pad-6-r {
  padding-right: 30px !important; }

.margin-6-rt {
  margin-right: 30px !important;
  margin-top: 30px !important; }

.pad-6-rt {
  padding-right: 30px !important;
  padding-top: 30px !important; }

.margin-6-rb {
  margin-right: 30px !important;
  margin-bottom: 30px !important; }

.pad-6-rb {
  padding-right: 30px !important;
  padding-bottom: 30px !important; }

.margin-6-rl {
  margin-right: 30px !important;
  margin-left: 30px !important; }

.pad-6-rl {
  padding-right: 30px !important;
  padding-left: 30px !important; }

.margin-6-rr {
  margin-right: 30px !important;
  margin-right: 30px !important; }

.pad-6-rr {
  padding-right: 30px !important;
  padding-right: 30px !important; }

.margin-7 {
  margin: 35px !important; }

.pad-7 {
  padding: 35px !important; }

.margin-7-t {
  margin-top: 35px !important; }

.margin-auto-t {
  margin-top: 35px !important; }

.pad-7-t {
  padding-top: 35px !important; }

.margin-7-tt {
  margin-top: 35px !important;
  margin-top: 35px !important; }

.pad-7-tt {
  padding-top: 35px !important;
  padding-top: 35px !important; }

.margin-7-tb {
  margin-top: 35px !important;
  margin-bottom: 35px !important; }

.pad-7-tb {
  padding-top: 35px !important;
  padding-bottom: 35px !important; }

.margin-7-tl {
  margin-top: 35px !important;
  margin-left: 35px !important; }

.pad-7-tl {
  padding-top: 35px !important;
  padding-left: 35px !important; }

.margin-7-tr {
  margin-top: 35px !important;
  margin-right: 35px !important; }

.pad-7-tr {
  padding-top: 35px !important;
  padding-right: 35px !important; }

.margin-7-b {
  margin-bottom: 35px !important; }

.margin-auto-b {
  margin-bottom: 35px !important; }

.pad-7-b {
  padding-bottom: 35px !important; }

.margin-7-bt {
  margin-bottom: 35px !important;
  margin-top: 35px !important; }

.pad-7-bt {
  padding-bottom: 35px !important;
  padding-top: 35px !important; }

.margin-7-bb {
  margin-bottom: 35px !important;
  margin-bottom: 35px !important; }

.pad-7-bb {
  padding-bottom: 35px !important;
  padding-bottom: 35px !important; }

.margin-7-bl {
  margin-bottom: 35px !important;
  margin-left: 35px !important; }

.pad-7-bl {
  padding-bottom: 35px !important;
  padding-left: 35px !important; }

.margin-7-br {
  margin-bottom: 35px !important;
  margin-right: 35px !important; }

.pad-7-br {
  padding-bottom: 35px !important;
  padding-right: 35px !important; }

.margin-7-l {
  margin-left: 35px !important; }

.margin-auto-l {
  margin-left: 35px !important; }

.pad-7-l {
  padding-left: 35px !important; }

.margin-7-lt {
  margin-left: 35px !important;
  margin-top: 35px !important; }

.pad-7-lt {
  padding-left: 35px !important;
  padding-top: 35px !important; }

.margin-7-lb {
  margin-left: 35px !important;
  margin-bottom: 35px !important; }

.pad-7-lb {
  padding-left: 35px !important;
  padding-bottom: 35px !important; }

.margin-7-ll {
  margin-left: 35px !important;
  margin-left: 35px !important; }

.pad-7-ll {
  padding-left: 35px !important;
  padding-left: 35px !important; }

.margin-7-lr {
  margin-left: 35px !important;
  margin-right: 35px !important; }

.pad-7-lr {
  padding-left: 35px !important;
  padding-right: 35px !important; }

.margin-7-r {
  margin-right: 35px !important; }

.margin-auto-r {
  margin-right: 35px !important; }

.pad-7-r {
  padding-right: 35px !important; }

.margin-7-rt {
  margin-right: 35px !important;
  margin-top: 35px !important; }

.pad-7-rt {
  padding-right: 35px !important;
  padding-top: 35px !important; }

.margin-7-rb {
  margin-right: 35px !important;
  margin-bottom: 35px !important; }

.pad-7-rb {
  padding-right: 35px !important;
  padding-bottom: 35px !important; }

.margin-7-rl {
  margin-right: 35px !important;
  margin-left: 35px !important; }

.pad-7-rl {
  padding-right: 35px !important;
  padding-left: 35px !important; }

.margin-7-rr {
  margin-right: 35px !important;
  margin-right: 35px !important; }

.pad-7-rr {
  padding-right: 35px !important;
  padding-right: 35px !important; }

.margin-8 {
  margin: 40px !important; }

.pad-8 {
  padding: 40px !important; }

.margin-8-t {
  margin-top: 40px !important; }

.margin-auto-t {
  margin-top: 40px !important; }

.pad-8-t {
  padding-top: 40px !important; }

.margin-8-tt {
  margin-top: 40px !important;
  margin-top: 40px !important; }

.pad-8-tt {
  padding-top: 40px !important;
  padding-top: 40px !important; }

.margin-8-tb {
  margin-top: 40px !important;
  margin-bottom: 40px !important; }

.pad-8-tb {
  padding-top: 40px !important;
  padding-bottom: 40px !important; }

.margin-8-tl {
  margin-top: 40px !important;
  margin-left: 40px !important; }

.pad-8-tl {
  padding-top: 40px !important;
  padding-left: 40px !important; }

.margin-8-tr {
  margin-top: 40px !important;
  margin-right: 40px !important; }

.pad-8-tr {
  padding-top: 40px !important;
  padding-right: 40px !important; }

.margin-8-b {
  margin-bottom: 40px !important; }

.margin-auto-b {
  margin-bottom: 40px !important; }

.pad-8-b {
  padding-bottom: 40px !important; }

.margin-8-bt {
  margin-bottom: 40px !important;
  margin-top: 40px !important; }

.pad-8-bt {
  padding-bottom: 40px !important;
  padding-top: 40px !important; }

.margin-8-bb {
  margin-bottom: 40px !important;
  margin-bottom: 40px !important; }

.pad-8-bb {
  padding-bottom: 40px !important;
  padding-bottom: 40px !important; }

.margin-8-bl {
  margin-bottom: 40px !important;
  margin-left: 40px !important; }

.pad-8-bl {
  padding-bottom: 40px !important;
  padding-left: 40px !important; }

.margin-8-br {
  margin-bottom: 40px !important;
  margin-right: 40px !important; }

.pad-8-br {
  padding-bottom: 40px !important;
  padding-right: 40px !important; }

.margin-8-l {
  margin-left: 40px !important; }

.margin-auto-l {
  margin-left: 40px !important; }

.pad-8-l {
  padding-left: 40px !important; }

.margin-8-lt {
  margin-left: 40px !important;
  margin-top: 40px !important; }

.pad-8-lt {
  padding-left: 40px !important;
  padding-top: 40px !important; }

.margin-8-lb {
  margin-left: 40px !important;
  margin-bottom: 40px !important; }

.pad-8-lb {
  padding-left: 40px !important;
  padding-bottom: 40px !important; }

.margin-8-ll {
  margin-left: 40px !important;
  margin-left: 40px !important; }

.pad-8-ll {
  padding-left: 40px !important;
  padding-left: 40px !important; }

.margin-8-lr {
  margin-left: 40px !important;
  margin-right: 40px !important; }

.pad-8-lr {
  padding-left: 40px !important;
  padding-right: 40px !important; }

.margin-8-r {
  margin-right: 40px !important; }

.margin-auto-r {
  margin-right: 40px !important; }

.pad-8-r {
  padding-right: 40px !important; }

.margin-8-rt {
  margin-right: 40px !important;
  margin-top: 40px !important; }

.pad-8-rt {
  padding-right: 40px !important;
  padding-top: 40px !important; }

.margin-8-rb {
  margin-right: 40px !important;
  margin-bottom: 40px !important; }

.pad-8-rb {
  padding-right: 40px !important;
  padding-bottom: 40px !important; }

.margin-8-rl {
  margin-right: 40px !important;
  margin-left: 40px !important; }

.pad-8-rl {
  padding-right: 40px !important;
  padding-left: 40px !important; }

.margin-8-rr {
  margin-right: 40px !important;
  margin-right: 40px !important; }

.pad-8-rr {
  padding-right: 40px !important;
  padding-right: 40px !important; }

.margin-9 {
  margin: 45px !important; }

.pad-9 {
  padding: 45px !important; }

.margin-9-t {
  margin-top: 45px !important; }

.margin-auto-t {
  margin-top: 45px !important; }

.pad-9-t {
  padding-top: 45px !important; }

.margin-9-tt {
  margin-top: 45px !important;
  margin-top: 45px !important; }

.pad-9-tt {
  padding-top: 45px !important;
  padding-top: 45px !important; }

.margin-9-tb {
  margin-top: 45px !important;
  margin-bottom: 45px !important; }

.pad-9-tb {
  padding-top: 45px !important;
  padding-bottom: 45px !important; }

.margin-9-tl {
  margin-top: 45px !important;
  margin-left: 45px !important; }

.pad-9-tl {
  padding-top: 45px !important;
  padding-left: 45px !important; }

.margin-9-tr {
  margin-top: 45px !important;
  margin-right: 45px !important; }

.pad-9-tr {
  padding-top: 45px !important;
  padding-right: 45px !important; }

.margin-9-b {
  margin-bottom: 45px !important; }

.margin-auto-b {
  margin-bottom: 45px !important; }

.pad-9-b {
  padding-bottom: 45px !important; }

.margin-9-bt {
  margin-bottom: 45px !important;
  margin-top: 45px !important; }

.pad-9-bt {
  padding-bottom: 45px !important;
  padding-top: 45px !important; }

.margin-9-bb {
  margin-bottom: 45px !important;
  margin-bottom: 45px !important; }

.pad-9-bb {
  padding-bottom: 45px !important;
  padding-bottom: 45px !important; }

.margin-9-bl {
  margin-bottom: 45px !important;
  margin-left: 45px !important; }

.pad-9-bl {
  padding-bottom: 45px !important;
  padding-left: 45px !important; }

.margin-9-br {
  margin-bottom: 45px !important;
  margin-right: 45px !important; }

.pad-9-br {
  padding-bottom: 45px !important;
  padding-right: 45px !important; }

.margin-9-l {
  margin-left: 45px !important; }

.margin-auto-l {
  margin-left: 45px !important; }

.pad-9-l {
  padding-left: 45px !important; }

.margin-9-lt {
  margin-left: 45px !important;
  margin-top: 45px !important; }

.pad-9-lt {
  padding-left: 45px !important;
  padding-top: 45px !important; }

.margin-9-lb {
  margin-left: 45px !important;
  margin-bottom: 45px !important; }

.pad-9-lb {
  padding-left: 45px !important;
  padding-bottom: 45px !important; }

.margin-9-ll {
  margin-left: 45px !important;
  margin-left: 45px !important; }

.pad-9-ll {
  padding-left: 45px !important;
  padding-left: 45px !important; }

.margin-9-lr {
  margin-left: 45px !important;
  margin-right: 45px !important; }

.pad-9-lr {
  padding-left: 45px !important;
  padding-right: 45px !important; }

.margin-9-r {
  margin-right: 45px !important; }

.margin-auto-r {
  margin-right: 45px !important; }

.pad-9-r {
  padding-right: 45px !important; }

.margin-9-rt {
  margin-right: 45px !important;
  margin-top: 45px !important; }

.pad-9-rt {
  padding-right: 45px !important;
  padding-top: 45px !important; }

.margin-9-rb {
  margin-right: 45px !important;
  margin-bottom: 45px !important; }

.pad-9-rb {
  padding-right: 45px !important;
  padding-bottom: 45px !important; }

.margin-9-rl {
  margin-right: 45px !important;
  margin-left: 45px !important; }

.pad-9-rl {
  padding-right: 45px !important;
  padding-left: 45px !important; }

.margin-9-rr {
  margin-right: 45px !important;
  margin-right: 45px !important; }

.pad-9-rr {
  padding-right: 45px !important;
  padding-right: 45px !important; }

.margin-10 {
  margin: 50px !important; }

.pad-10 {
  padding: 50px !important; }

.margin-10-t {
  margin-top: 50px !important; }

.margin-auto-t {
  margin-top: 50px !important; }

.pad-10-t {
  padding-top: 50px !important; }

.margin-10-tt {
  margin-top: 50px !important;
  margin-top: 50px !important; }

.pad-10-tt {
  padding-top: 50px !important;
  padding-top: 50px !important; }

.margin-10-tb {
  margin-top: 50px !important;
  margin-bottom: 50px !important; }

.pad-10-tb {
  padding-top: 50px !important;
  padding-bottom: 50px !important; }

.margin-10-tl {
  margin-top: 50px !important;
  margin-left: 50px !important; }

.pad-10-tl {
  padding-top: 50px !important;
  padding-left: 50px !important; }

.margin-10-tr {
  margin-top: 50px !important;
  margin-right: 50px !important; }

.pad-10-tr {
  padding-top: 50px !important;
  padding-right: 50px !important; }

.margin-10-b {
  margin-bottom: 50px !important; }

.margin-auto-b {
  margin-bottom: 50px !important; }

.pad-10-b {
  padding-bottom: 50px !important; }

.margin-10-bt {
  margin-bottom: 50px !important;
  margin-top: 50px !important; }

.pad-10-bt {
  padding-bottom: 50px !important;
  padding-top: 50px !important; }

.margin-10-bb {
  margin-bottom: 50px !important;
  margin-bottom: 50px !important; }

.pad-10-bb {
  padding-bottom: 50px !important;
  padding-bottom: 50px !important; }

.margin-10-bl {
  margin-bottom: 50px !important;
  margin-left: 50px !important; }

.pad-10-bl {
  padding-bottom: 50px !important;
  padding-left: 50px !important; }

.margin-10-br {
  margin-bottom: 50px !important;
  margin-right: 50px !important; }

.pad-10-br {
  padding-bottom: 50px !important;
  padding-right: 50px !important; }

.margin-10-l {
  margin-left: 50px !important; }

.margin-auto-l {
  margin-left: 50px !important; }

.pad-10-l {
  padding-left: 50px !important; }

.margin-10-lt {
  margin-left: 50px !important;
  margin-top: 50px !important; }

.pad-10-lt {
  padding-left: 50px !important;
  padding-top: 50px !important; }

.margin-10-lb {
  margin-left: 50px !important;
  margin-bottom: 50px !important; }

.pad-10-lb {
  padding-left: 50px !important;
  padding-bottom: 50px !important; }

.margin-10-ll {
  margin-left: 50px !important;
  margin-left: 50px !important; }

.pad-10-ll {
  padding-left: 50px !important;
  padding-left: 50px !important; }

.margin-10-lr {
  margin-left: 50px !important;
  margin-right: 50px !important; }

.pad-10-lr {
  padding-left: 50px !important;
  padding-right: 50px !important; }

.margin-10-r {
  margin-right: 50px !important; }

.margin-auto-r {
  margin-right: 50px !important; }

.pad-10-r {
  padding-right: 50px !important; }

.margin-10-rt {
  margin-right: 50px !important;
  margin-top: 50px !important; }

.pad-10-rt {
  padding-right: 50px !important;
  padding-top: 50px !important; }

.margin-10-rb {
  margin-right: 50px !important;
  margin-bottom: 50px !important; }

.pad-10-rb {
  padding-right: 50px !important;
  padding-bottom: 50px !important; }

.margin-10-rl {
  margin-right: 50px !important;
  margin-left: 50px !important; }

.pad-10-rl {
  padding-right: 50px !important;
  padding-left: 50px !important; }

.margin-10-rr {
  margin-right: 50px !important;
  margin-right: 50px !important; }

.pad-10-rr {
  padding-right: 50px !important;
  padding-right: 50px !important; }

.margin-15 {
  margin: 75px !important; }

.pad-15 {
  padding: 75px !important; }

.margin-15-t {
  margin-top: 75px !important; }

.margin-auto-t {
  margin-top: 75px !important; }

.pad-15-t {
  padding-top: 75px !important; }

.margin-15-tt {
  margin-top: 75px !important;
  margin-top: 75px !important; }

.pad-15-tt {
  padding-top: 75px !important;
  padding-top: 75px !important; }

.margin-15-tb {
  margin-top: 75px !important;
  margin-bottom: 75px !important; }

.pad-15-tb {
  padding-top: 75px !important;
  padding-bottom: 75px !important; }

.margin-15-tl {
  margin-top: 75px !important;
  margin-left: 75px !important; }

.pad-15-tl {
  padding-top: 75px !important;
  padding-left: 75px !important; }

.margin-15-tr {
  margin-top: 75px !important;
  margin-right: 75px !important; }

.pad-15-tr {
  padding-top: 75px !important;
  padding-right: 75px !important; }

.margin-15-b {
  margin-bottom: 75px !important; }

.margin-auto-b {
  margin-bottom: 75px !important; }

.pad-15-b {
  padding-bottom: 75px !important; }

.margin-15-bt {
  margin-bottom: 75px !important;
  margin-top: 75px !important; }

.pad-15-bt {
  padding-bottom: 75px !important;
  padding-top: 75px !important; }

.margin-15-bb {
  margin-bottom: 75px !important;
  margin-bottom: 75px !important; }

.pad-15-bb {
  padding-bottom: 75px !important;
  padding-bottom: 75px !important; }

.margin-15-bl {
  margin-bottom: 75px !important;
  margin-left: 75px !important; }

.pad-15-bl {
  padding-bottom: 75px !important;
  padding-left: 75px !important; }

.margin-15-br {
  margin-bottom: 75px !important;
  margin-right: 75px !important; }

.pad-15-br {
  padding-bottom: 75px !important;
  padding-right: 75px !important; }

.margin-15-l {
  margin-left: 75px !important; }

.margin-auto-l {
  margin-left: 75px !important; }

.pad-15-l {
  padding-left: 75px !important; }

.margin-15-lt {
  margin-left: 75px !important;
  margin-top: 75px !important; }

.pad-15-lt {
  padding-left: 75px !important;
  padding-top: 75px !important; }

.margin-15-lb {
  margin-left: 75px !important;
  margin-bottom: 75px !important; }

.pad-15-lb {
  padding-left: 75px !important;
  padding-bottom: 75px !important; }

.margin-15-ll {
  margin-left: 75px !important;
  margin-left: 75px !important; }

.pad-15-ll {
  padding-left: 75px !important;
  padding-left: 75px !important; }

.margin-15-lr {
  margin-left: 75px !important;
  margin-right: 75px !important; }

.pad-15-lr {
  padding-left: 75px !important;
  padding-right: 75px !important; }

.margin-15-r {
  margin-right: 75px !important; }

.margin-auto-r {
  margin-right: 75px !important; }

.pad-15-r {
  padding-right: 75px !important; }

.margin-15-rt {
  margin-right: 75px !important;
  margin-top: 75px !important; }

.pad-15-rt {
  padding-right: 75px !important;
  padding-top: 75px !important; }

.margin-15-rb {
  margin-right: 75px !important;
  margin-bottom: 75px !important; }

.pad-15-rb {
  padding-right: 75px !important;
  padding-bottom: 75px !important; }

.margin-15-rl {
  margin-right: 75px !important;
  margin-left: 75px !important; }

.pad-15-rl {
  padding-right: 75px !important;
  padding-left: 75px !important; }

.margin-15-rr {
  margin-right: 75px !important;
  margin-right: 75px !important; }

.pad-15-rr {
  padding-right: 75px !important;
  padding-right: 75px !important; }

.margin-20 {
  margin: 100px !important; }

.pad-20 {
  padding: 100px !important; }

.margin-20-t {
  margin-top: 100px !important; }

.margin-auto-t {
  margin-top: 100px !important; }

.pad-20-t {
  padding-top: 100px !important; }

.margin-20-tt {
  margin-top: 100px !important;
  margin-top: 100px !important; }

.pad-20-tt {
  padding-top: 100px !important;
  padding-top: 100px !important; }

.margin-20-tb {
  margin-top: 100px !important;
  margin-bottom: 100px !important; }

.pad-20-tb {
  padding-top: 100px !important;
  padding-bottom: 100px !important; }

.margin-20-tl {
  margin-top: 100px !important;
  margin-left: 100px !important; }

.pad-20-tl {
  padding-top: 100px !important;
  padding-left: 100px !important; }

.margin-20-tr {
  margin-top: 100px !important;
  margin-right: 100px !important; }

.pad-20-tr {
  padding-top: 100px !important;
  padding-right: 100px !important; }

.margin-20-b {
  margin-bottom: 100px !important; }

.margin-auto-b {
  margin-bottom: 100px !important; }

.pad-20-b {
  padding-bottom: 100px !important; }

.margin-20-bt {
  margin-bottom: 100px !important;
  margin-top: 100px !important; }

.pad-20-bt {
  padding-bottom: 100px !important;
  padding-top: 100px !important; }

.margin-20-bb {
  margin-bottom: 100px !important;
  margin-bottom: 100px !important; }

.pad-20-bb {
  padding-bottom: 100px !important;
  padding-bottom: 100px !important; }

.margin-20-bl {
  margin-bottom: 100px !important;
  margin-left: 100px !important; }

.pad-20-bl {
  padding-bottom: 100px !important;
  padding-left: 100px !important; }

.margin-20-br {
  margin-bottom: 100px !important;
  margin-right: 100px !important; }

.pad-20-br {
  padding-bottom: 100px !important;
  padding-right: 100px !important; }

.margin-20-l {
  margin-left: 100px !important; }

.margin-auto-l {
  margin-left: 100px !important; }

.pad-20-l {
  padding-left: 100px !important; }

.margin-20-lt {
  margin-left: 100px !important;
  margin-top: 100px !important; }

.pad-20-lt {
  padding-left: 100px !important;
  padding-top: 100px !important; }

.margin-20-lb {
  margin-left: 100px !important;
  margin-bottom: 100px !important; }

.pad-20-lb {
  padding-left: 100px !important;
  padding-bottom: 100px !important; }

.margin-20-ll {
  margin-left: 100px !important;
  margin-left: 100px !important; }

.pad-20-ll {
  padding-left: 100px !important;
  padding-left: 100px !important; }

.margin-20-lr {
  margin-left: 100px !important;
  margin-right: 100px !important; }

.pad-20-lr {
  padding-left: 100px !important;
  padding-right: 100px !important; }

.margin-20-r {
  margin-right: 100px !important; }

.margin-auto-r {
  margin-right: 100px !important; }

.pad-20-r {
  padding-right: 100px !important; }

.margin-20-rt {
  margin-right: 100px !important;
  margin-top: 100px !important; }

.pad-20-rt {
  padding-right: 100px !important;
  padding-top: 100px !important; }

.margin-20-rb {
  margin-right: 100px !important;
  margin-bottom: 100px !important; }

.pad-20-rb {
  padding-right: 100px !important;
  padding-bottom: 100px !important; }

.margin-20-rl {
  margin-right: 100px !important;
  margin-left: 100px !important; }

.pad-20-rl {
  padding-right: 100px !important;
  padding-left: 100px !important; }

.margin-20-rr {
  margin-right: 100px !important;
  margin-right: 100px !important; }

.pad-20-rr {
  padding-right: 100px !important;
  padding-right: 100px !important; }

.margin-30 {
  margin: 150px !important; }

.pad-30 {
  padding: 150px !important; }

.margin-30-t {
  margin-top: 150px !important; }

.margin-auto-t {
  margin-top: 150px !important; }

.pad-30-t {
  padding-top: 150px !important; }

.margin-30-tt {
  margin-top: 150px !important;
  margin-top: 150px !important; }

.pad-30-tt {
  padding-top: 150px !important;
  padding-top: 150px !important; }

.margin-30-tb {
  margin-top: 150px !important;
  margin-bottom: 150px !important; }

.pad-30-tb {
  padding-top: 150px !important;
  padding-bottom: 150px !important; }

.margin-30-tl {
  margin-top: 150px !important;
  margin-left: 150px !important; }

.pad-30-tl {
  padding-top: 150px !important;
  padding-left: 150px !important; }

.margin-30-tr {
  margin-top: 150px !important;
  margin-right: 150px !important; }

.pad-30-tr {
  padding-top: 150px !important;
  padding-right: 150px !important; }

.margin-30-b {
  margin-bottom: 150px !important; }

.margin-auto-b {
  margin-bottom: 150px !important; }

.pad-30-b {
  padding-bottom: 150px !important; }

.margin-30-bt {
  margin-bottom: 150px !important;
  margin-top: 150px !important; }

.pad-30-bt {
  padding-bottom: 150px !important;
  padding-top: 150px !important; }

.margin-30-bb {
  margin-bottom: 150px !important;
  margin-bottom: 150px !important; }

.pad-30-bb {
  padding-bottom: 150px !important;
  padding-bottom: 150px !important; }

.margin-30-bl {
  margin-bottom: 150px !important;
  margin-left: 150px !important; }

.pad-30-bl {
  padding-bottom: 150px !important;
  padding-left: 150px !important; }

.margin-30-br {
  margin-bottom: 150px !important;
  margin-right: 150px !important; }

.pad-30-br {
  padding-bottom: 150px !important;
  padding-right: 150px !important; }

.margin-30-l {
  margin-left: 150px !important; }

.margin-auto-l {
  margin-left: 150px !important; }

.pad-30-l {
  padding-left: 150px !important; }

.margin-30-lt {
  margin-left: 150px !important;
  margin-top: 150px !important; }

.pad-30-lt {
  padding-left: 150px !important;
  padding-top: 150px !important; }

.margin-30-lb {
  margin-left: 150px !important;
  margin-bottom: 150px !important; }

.pad-30-lb {
  padding-left: 150px !important;
  padding-bottom: 150px !important; }

.margin-30-ll {
  margin-left: 150px !important;
  margin-left: 150px !important; }

.pad-30-ll {
  padding-left: 150px !important;
  padding-left: 150px !important; }

.margin-30-lr {
  margin-left: 150px !important;
  margin-right: 150px !important; }

.pad-30-lr {
  padding-left: 150px !important;
  padding-right: 150px !important; }

.margin-30-r {
  margin-right: 150px !important; }

.margin-auto-r {
  margin-right: 150px !important; }

.pad-30-r {
  padding-right: 150px !important; }

.margin-30-rt {
  margin-right: 150px !important;
  margin-top: 150px !important; }

.pad-30-rt {
  padding-right: 150px !important;
  padding-top: 150px !important; }

.margin-30-rb {
  margin-right: 150px !important;
  margin-bottom: 150px !important; }

.pad-30-rb {
  padding-right: 150px !important;
  padding-bottom: 150px !important; }

.margin-30-rl {
  margin-right: 150px !important;
  margin-left: 150px !important; }

.pad-30-rl {
  padding-right: 150px !important;
  padding-left: 150px !important; }

.margin-30-rr {
  margin-right: 150px !important;
  margin-right: 150px !important; }

.pad-30-rr {
  padding-right: 150px !important;
  padding-right: 150px !important; }

.article p img {
  display: block;
  margin-left: auto;
  margin-right: auto;
  max-width: 600px;
  width: 100%; }

.article pre code {
  background: none;
  display: block;
  line-height: 1.42;
  width: auto; }

.article pre {
  border-radius: 3px;
  font-size: 1rem;
  overflow-x: auto;
  padding: 1.45rem;
  word-wrap: break-word; }

.article h3 {
  font-size: 2rem; }

.article h4 {
  font-size: 1.5rem; }

.article img {
  max-width: 100%; }

.article p code {
  word-wrap: break-word; }

.article ul li h2 a {
  text-decoration: none; }

.blog-poster {
  max-height: 500px;
  text-align: center; }

.btn {
  font-family: Lato;
  max-width: 250px;
  padding: 15px;
  text-align: center; }

.btn:hover {
  outline-style: solid;
  outline-width: 3px;
  cursor: pointer; }

.btn-variant {
  font-family: Lato;
  max-width: 250px;
  padding: 15px;
  text-align: center; }

.btn-variant:hover {
  border-style: solid;
  border-width: 3px;
  cursor: pointer;
  margin: -3px; }

.link {
  font-weight: 100;
  text-decoration: none; }

.link:hover {
  cursor: pointer;
  transform: scale(1.05);
  transition: all 0.2s ease-in-out; }

button,
input[type='submit'],
input[type='reset'] {
  background: none;
  border: none;
  cursor: pointer;
  font: inherit;
  outline: inherit;
  padding: 0; }

.is-hero-sub-text {
  font-family: Lato;
  font-size: calc(20px);
  line-height: calc(20px);
  margin-bottom: 5px;
  margin-top: 10px; }

.is-hero-menu {
  display: block;
  font-family: Lato;
  font-size: 3em;
  font-weight: bold;
  margin-bottom: 0.67em;
  margin-left: 0;
  margin-right: 0;
  margin-top: 0.67em; }

.vertical-timeline {
  margin: 0 auto;
  max-width: 1170px;
  padding: 2em 0;
  position: relative;
  width: 95%; }

.vertical-timeline {
  margin-bottom: 25px !important;
  margin-top: 5px !important; }

.vertical-timeline {
  margin-bottom: 25px !important;
  margin-top: 5px !important; }

.vertical-timeline-element-content {
  border-style: solid;
  border-width: 3px; }
  .vertical-timeline-element-content .vertical-timeline-element-content-arrow {
    border-right: 7px solid #444; }

body.dark-mode .vertical-timeline {
  margin: 0 auto;
  max-width: 1170px;
  padding: 2em 0;
  position: relative;
  width: 95%; }

body.dark-mode .vertical-timeline-element-content {
  border-style: solid;
  border-width: 3px;
  box-shadow: none; }

.flex {
  display: flex; }

.align-horizontal {
  align-items: center; }

.align-vertical {
  flex-direction: column;
  justify-content: center; }

.text-align-right {
  text-align: right; }

.text-align-center {
  text-align: center; }

.lato {
  font-family: Lato; }

.bold {
  font-weight: bold; }

.line {
  border-bottom: 7px solid;
  max-width: 30%; }

.line-sm {
  border-bottom: 3px solid;
  max-width: 100%;
  opacity: 0.5; }

.grow {
  transition: all 0.2s ease-in-out; }
  .grow:hover {
    transform: scale(1.02); }

.shadow {
  box-shadow: 0 0 0 3px rgba(0, 0, 0, 0.03); }

.hover:hover {
  color: #ef6461;
  cursor: pointer; }

.border-radius {
  border-radius: 8px; }

.slick-slide {
  pointer-events: none; }

.slide-image-one {
  background-image: url("https://ik.imagekit.io/sld/ICHACK_a2o31d1eR.png");
  background-position: right; }

.slide-image-two {
  background-image: url("https://ik.imagekit.io/sld/HackKings_nVVodPVC9.jpeg");
  background-position: center; }

.slide-image-three {
  background-image: url("https://ik.imagekit.io/sld/AmexOnboarding_my-fjWmR6.jpeg");
  background-position: right; }

.firework-window {
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100vw;
  z-index: 100;
  height: calc(100vh);
  pointer-events: none; }

h6 {
  font-size: 1.4em; }

h5 {
  font-size: 2.8em; }

h4 {
  font-size: 4.2em; }

h3 {
  font-size: 5.6em; }

h2 {
  font-size: 7em; }

h1 {
  font-size: 8.4em; }

html {
  max-width: 100vw;
  overflow-x: hidden; }

main {
  min-height: calc(100vh - 150px); }

body {
  margin: 0;
  max-width: 100vw; }

p {
  font-family: Lato;
  font-weight: lighter; }

h1 {
  display: block;
  font-family: Lato;
  font-size: 2em;
  font-weight: bold;
  margin-bottom: 0.67em;
  margin-left: 0;
  margin-right: 0;
  margin-top: 0.67em; }

h2 {
  display: block;
  font-family: Lato;
  font-size: 1.5em;
  font-weight: bold;
  margin-bottom: 0.83em;
  margin-left: 0;
  margin-right: 0;
  margin-top: 0.83em; }

h3 {
  display: block;
  font-family: Lato;
  font-size: 1.17em;
  font-weight: bold;
  margin-bottom: 1em;
  margin-left: 0;
  margin-right: 0;
  margin-top: 1em; }

h4 {
  display: block;
  font-family: Lato;
  font-size: 1em;
  font-weight: bold;
  margin-bottom: 1.33em;
  margin-left: 0;
  margin-right: 0;
  margin-top: 1.33em; }

h5 {
  display: block;
  font-family: Lato;
  font-size: 0.83em;
  font-weight: bold;
  margin-bottom: 1.67em;
  margin-left: 0;
  margin-right: 0;
  margin-top: 1.67em; }

h6 {
  display: block;
  font-family: Lato;
  font-size: 0.67em;
  font-weight: bold;
  margin-bottom: 2.33em;
  margin-left: 0;
  margin-right: 0;
  margin-top: 2.33em; }

hr {
  margin-bottom: 1vw;
  margin-top: 2vw; }

div {
  display: block; }

footer {
  font-family: Lato;
  text-align: center; }

.legal {
  font-size: 10px; }

.stepper-item-inner-active {
  background-color: white !important; }

.stepper-container .stepper-item .stepper-title {
  max-width: none !important;
  min-width: 0px !important;
  color: white !important;
  font-family: Lato !important; }

.stepper-item-outer {
  border-color: #00000030 !important; }

.stepper-item-inner-future {
  background-color: #00000030 !important; }

