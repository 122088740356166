//fonts
@import url('https://fonts.googleapis.com/css?family=Lato:700&display=swap');


//files;
@import './layout';
@import './colors';
@import './paper';
@import './inputs';
@import './opacity';
@import './margin_padding';
@import './article';
@import './buttons';
@import './hero';
@import './timeline';
@import './utilities';

@for $i from 1 through 6 {
  h#{7 - $i} {
    font-size: $i * 1.4em;
  }
}

html {
  max-width: 100vw;
  overflow-x: hidden;
}

main {
  min-height: calc(100vh - 150px);
}

body {
  margin: 0;
  max-width: 100vw;
}

p {
  font-family: Lato;
  font-weight: lighter;
}


h1 {
  display: block;
  font-family: Lato;
  font-size: 2em;
  font-weight: bold;
  margin-bottom: 0.67em;
  margin-left: 0;
  margin-right: 0;
  margin-top: 0.67em;
}

h2 {
  display: block;
  font-family: Lato;
  font-size: 1.5em;
  font-weight: bold;
  margin-bottom: 0.83em;
  margin-left: 0;
  margin-right: 0;
  margin-top: 0.83em;
}

h3 {
  display: block;
  font-family: Lato;
  font-size: 1.17em;
  font-weight: bold;
  margin-bottom: 1em;
  margin-left: 0;
  margin-right: 0;
  margin-top: 1em;
}

h4 {
  display: block;
  font-family: Lato;
  font-size: 1em;
  font-weight: bold;
  margin-bottom: 1.33em;
  margin-left: 0;
  margin-right: 0;
  margin-top: 1.33em;
}

h5 {
  display: block;
  font-family: Lato;
  font-size: 0.83em;
  font-weight: bold;
  margin-bottom: 1.67em;
  margin-left: 0;
  margin-right: 0;
  margin-top: 1.67em;
}

h6 {
  display: block;
  font-family: Lato;
  font-size: 0.67em;
  font-weight: bold;
  margin-bottom: 2.33em;
  margin-left: 0;
  margin-right: 0;
  margin-top: 2.33em;
}

hr {
  margin-bottom: 1vw;
  margin-top: 2vw;
}

div {
  display: block;
}

footer {
  font-family: Lato;
  text-align: center;
}

.legal {
  font-size: 10px;
}

.stepper-item-inner-active {
  background-color: white !important;
}

.stepper-container .stepper-item .stepper-title  {
  max-width: none !important;
  min-width: 0px !important;
  color: white !important;
  font-family: Lato !important;
}

.stepper-item-outer {
  border-color: #00000030 !important;
}

.stepper-item-inner-future {
  background-color:  #00000030 !important;
}