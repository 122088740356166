.paper {
  background: #fff;
  box-shadow:
    /* The top layer shadow */
    0 -1px 1px rgba(0, 0, 0, 0.15),
    
    0 -10px 0 -5px #eee,
    
    0 -10px 1px -4px rgba(0, 0, 0, 0.15),
     
    0 -20px 0 -10px #eee,
    
    0 -20px 1px -9px    (0,0,0,0.15);
  font-family: Lato;
  /* Padding for demo purposes */
  // padding: 30px;

   
  .link {
    @extend .is-light-blue; }

  hr {
    opacity: 0.3;
  }
}

