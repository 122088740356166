

.article {
  p img {
    display: block;
    margin-left: auto;
    margin-right: auto;
    max-width: 600px;
    width: 100%;
  }
     

  pre code {
    @extend .is-black;
    background: none;
    display: block;
    line-height: 1.42;
    width: auto;
  }

  pre {
    @extend .is-dark-pink-bg;
    border-radius: 3px;
    font-size: 1rem;
    overflow-x: auto;
    padding: 1.45rem;
    word-wrap: break-word;

  }

  a {
    @extend .is-light-blue-always;
  }

  h3 {
    font-size: 2rem;
  }

  h4 {
    font-size: 1.5rem;
  }

  img {
    max-width: 100%;
  }

  p code {
       
    word-wrap: break-word;
         
  }

  ul li h2 a {
    @extend .is-pink-always;
    text-decoration: none;
  }

  h1 {
    @extend .is-pink-always;
  }
   
}

.blog-poster {
  max-height: 500px;
  text-align: center;
}
