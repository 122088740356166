
.flex {
  display: flex;
}

.align-horizontal {
  align-items: center;
}

.align-vertical {
  flex-direction: column;
  justify-content: center;
}

.text-align-right {
  text-align: right;
}

.text-align-center {
  text-align: center;
}

.lato {
  font-family: Lato;
}

.bold {
  font-weight: bold;
}

.line {
  border-bottom: 7px solid;
  max-width: 30%;
}

.line-sm {
  border-bottom: 3px solid;
  max-width: 100%;
  opacity: 0.5;
}


.grow {
  transition: all 0.2s ease-in-out;

  &:hover {
    transform: scale(1.02);
  }
}


.shadow {
  -webkit-box-shadow: 0 0 0 3px rgba(0, 0, 0, 0.03);
  -moz-box-shadow: 0 0 0 3px rgba(0, 0, 0, 0.03);
  box-shadow: 0 0 0 3px rgba(0, 0, 0, 0.03);
}

.hover:hover {
  color: #ef6461;
  cursor: pointer;
}

.border-radius {
  border-radius: 8px;
}

.slick-slide {
  pointer-events: none;
}
.slide-image-one {
  background-image: url('https://ik.imagekit.io/sld/ICHACK_a2o31d1eR.png');
  background-position: right;
}


.slide-image-two {
  background-image: url('https://ik.imagekit.io/sld/HackKings_nVVodPVC9.jpeg');
  background-position: center;
}

.slide-image-three {
  background-image: url('https://ik.imagekit.io/sld/AmexOnboarding_my-fjWmR6.jpeg');
  background-position: right;
}

.firework-window {
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100vw;
  z-index: 100;
  height:  calc(100vh);
  pointer-events: none;
}