.is-hero-sub-text {
  font-family: Lato;
  font-size: calc(20px);
  line-height: calc(20px);
  margin-bottom: 5px;
  margin-top: 10px;

}

.is-hero-menu {
  display: block;
  font-family: Lato;
  font-size: 3em;
  font-weight: bold;
  margin-bottom: 0.67em;
  margin-left: 0;
  margin-right: 0;
  margin-top: 0.67em;
}


